
import ShrinkIcon from "vue-material-design-icons/PictureInPictureTopRight.vue"
import ExpandIcon from "vue-material-design-icons/ArrowExpand.vue"

export default {
  components: {
    ExpandIcon,
    ShrinkIcon,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
    cancelable: {
      type: Boolean,
    },
    mini: {
      type: Boolean,
    },
  },

  data() {
    return {
      radius: 340,
      font: 7,
      animating: false,
      percent: 0,
      countdown: null,
      countdown_timer: null,
      frame: null,
      shrink: false,
      transitioning: false,
    }
  },

  methods: {
    tickCountdown(end_at) {
      if (!end_at) return
      if (!this.animating) return

      this.countdown = this.$moment(end_at).diff(this.$moment())
      this.countdown = (this.countdown / 1000).toFixed(2)
      this.percent = 100 - this.countdown.split(".")[1]
      if (this.countdown >= 5) this.countdown = "5"
      if (this.countdown >= 5) this.percent = 0
      if (this.countdown < 0) this.countdown = "0"
      if (this.countdown == -0) this.countdown = "0"
      if (this.countdown == 0) this.percent = 100

      this.frame = requestAnimationFrame(() => {
        this.tickCountdown(end_at)
      })
    },

    stopCountdown() {
      this.animating = false
      this.countdown_timer = null
      this.countdown = null
      this.percent = 0
      if (this.frame) cancelAnimationFrame(this.frame)
    },

    cancel() {
      this.$emit("cancel_countdown")
    },

    setRadius() {
      if (this.mini) {
        this.font = 2
        this.radius = 50
        return
      }

      const w = window.innerWidth
      let r
      let f

      if (w >= 768) {
        r = 340
        f = 7
      } else {
        r = 190
        f = 5
      }

      this.font = f
      this.radius = r
    },

    toggleShrink() {
      this.shrink = !this.shrink
      this.$store.commit("SET_COUNTDOWN_SHRINK", this.shrink)
    },
  },

  computed: {
    deg() {
      return (360 / 100) * this.percent
    },

    grd() {
      const light = `rgb(209, 168, 22)`
      const dark = `rgb(115, 93, 17)`
      return `conic-gradient(${light} 0deg, ${dark} ${this.deg / 2}deg, ${light} ${
        this.deg
      }deg, transparent ${this.deg}deg)`
    },

    countdown_number() {
      if (this.countdown == 0) return 0
      const n = parseInt(this.countdown?.split(".")[0]) + 1
      if (n >= 5) return 5
      return n
    },

    show() {
      // return true // for debug use, please keep
      return this.item.status == 11
    },
  },

  watch: {
    "item._update_type": function (v) {
      if (v == "countdown:start") {
        if (this.frame) {
          cancelAnimationFrame(this.frame)
        }

        this.frame = requestAnimationFrame(() => {
          this.animating = true
          this.tickCountdown(this.item.countdown_end_at)
        })

        this.$pubsub.$emit("countdown_zero", { id: this.item.id, zero: false })
      }

      if (v == "countdown:cancelled") {
        this.stopCountdown()
      }
    },

    "item.status": function (v) {
      if ([5, 6, 7].includes(v)) {
        this.countdown = null
      }
    },

    countdown(v) {
      if (v === "0") {
        this.$pubsub.$emit("countdown_zero", { id: this.item.id, zero: true })
      }

      if (v === null) {
        this.$pubsub.$emit("countdown_zero", { id: this.item.id, zero: false })
      }
    },

    shrink() {
      this.setRadius()
      this.transitioning = true
      setTimeout(() => {
        this.transitioning = false
      }, 300)
    },
  },

  created() {
    this.shrink = !this.mini && this.$store.state.shrink_countdown
    this.setRadius()
    window.addEventListener("resize", this.setRadius)

    // For debugging - please keep
    // this.animating = true
    // this.tickCountdown(this.$moment().add(10, "seconds"))
  },

  beforeDestroy() {
    this.stopCountdown()
    window.removeEventListener("resize", this.setRadius)
  },
}
