import VuexPersistence from "vuex-persist"

export default ({ store }) => {
  /*
   * Local Storage
   */
  new VuexPersistence({
    storage: window.localStorage,
    reducer: (state) => ({
      version: state.version,
      source: state.source,
      session_affiliate: state.session_affiliate,
      uploads: {
        itemInput: state.uploads.itemInput,
      },
    }),
  }).plugin(store)

  /*
   * Session Storage
   */
  new VuexPersistence({
    storage: window.sessionStorage,
    reducer: (state) => ({
      source: state.source,
      auction: {
        eventQueries: state.auction.eventQueries,
      },
      // === DEBUG BID BAR VARIANT ===
      bidBarVariant: state.bidBarVariant,
      // === DEBUG BID BAR VARIANT END ===
    }),
  }).plugin(store)
}
