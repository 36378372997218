
export default {
  props: {
    size: {
      type: Number,
      default: 20,
    },
    color: {
      type: String,
      default: "light",
    },
  },

  data() {
    return {
      filters: {
        light:
          "invert(100%) sepia(0%) saturate(1%) hue-rotate(331deg) brightness(103%) contrast(101%)",
        dark: "invert(5%) sepia(0%) saturate(36%) hue-rotate(185deg) brightness(104%) contrast(82%)",
        primary:
          "invert(50%) sepia(45%) saturate(760%) hue-rotate(9deg) brightness(91%) contrast(91%)",
      },
    }
  },

  computed: {
    spinnerStyle: function () {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
        filter: this.filters[this.color],
      }
    },
  },
}
