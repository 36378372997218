const baseErrors = ({ offer, current_price, inc }) => {
  let errors = []

  if (!offer || (offer && offer <= current_price)) {
    errors.push("price_too_low")
  }

  let step_ok = offer % inc == 0
  if (!step_ok) {
    errors.push("bad_increment")
  }

  return errors
}

export const validateNegotiationOfferFromSeller = ({ offer, current_price, minimum_price, inc }) => {
  let errors = baseErrors({ offer, current_price, inc })

  if (offer > minimum_price) {
    errors.push("should_be_lte_to_min_price")
  }

  return errors
}

export const validateNegotiationOfferFromBuyer = ({ offer, current_price, seller_offer, inc }) => {
  let errors = baseErrors({ offer, current_price, inc })

  if (offer >= seller_offer) {
    errors.push("should_be_less_than_sellers_offer")
  }

  return errors
}

