import Vue from "vue"
import { mergeItem, updateItem } from "./index"

export const state = () => ({
  all: {},
  err: {},

  // collections
  search: null,
  box: null,
  live: null,
  memos: null,
  likes: null,
  home_likes: null,
  recommended_items: null,
  recent_items: null,
  bidding_items: null,
  seller_items: null,
  post_negotiations: null,

  // grouped collections
  won: null,
  held: null,
  pulled_after_held: null,
  sold_items: null,
  post_negotiation_available: null,

  groupMeta: {},

  bid_modal_id: null,
  bid_price: null,

  bid_configs: {},
})

export const getters = {
  list_events: (state) => (name) => {
    return state[name]?.events?.collection
  },

  list_ids: (state) => (key) => {
    return state[key]?.ids
  },

  grouped_list_events: (state) => (type) => {
    return state.groupMeta[type]?.events || []
  },
}

export const mutations = {
  SET_ITEM_ERR(state, { id, data }) {
    state.err = { ...state.err, [id]: data }
  },
  CLEAR_ITEM_ERR(state, id) {
    state.err = { ...state.err, [id]: null }
    delete state.err[id]
  },
  UPDATE_ITEM(state, data) {
    if (data.bid_config) {
      state.bid_configs = { ...state.bid_configs, [data.event_id]: data.bid_config }
      delete data.bid_config
    }
    const item = updateItem(state, "all", data)
    if (item) state.all = { ...state.all, [item.id]: item }
  },
  SET_COLLECTION(state, { name, data }) {
    state[name] = {
      ...state[name],
      ids: data.collection.map((e) => e.id),
      meta: data.meta,
      ids_token: data.ids_token,
    }

    for (const item of data.collection) {
      const existing = state.all[item.id]
      Vue.set(state.all, item.id, existing ? mergeItem(existing, item) : item)
    }
    state.bid_configs = { ...state.bid_configs, ...data.bid_configs }
  },
  SET_COLLECTION_LOADING(state, { name, v }) {
    state[name] = { ...state[name], loading: v }
  },
  SET_COLLECTION_EVENTS(state, { name, data }) {
    state[name] = { ...state[name], events: data }
  },
  SET_GROUPED_COLLECTION(state, { name, data }) {
    state[name] = {
      ...state[name],
      groups: data.collection.map((e) => ({
        ...e,
        ids: e.items.map((e) => e.id),
      })),
      meta: data.meta,
      ids_token: data.ids_token,
    }

    for (const group of data.collection) {
      for (const item of group.items) {
        const existing = state.all[item.id]
        Vue.set(state.all, item.id, existing ? mergeItem(existing, item) : item)
      }
    }
    state.bid_configs = { ...state.bid_configs, ...data.bid_configs }
  },
  SET_GROUP_META(state, { type, data }) {
    state.groupMeta = { ...state.groupMeta, [type]: data }
  },
  SET_BIDDING_ITEMS_TOTALS(state, totals) {
    if (!state.bidding_items) state.bidding_items = {}
    state.bidding_items = { ...state.bidding_items, totals }
  },
  CLEANUP(state, keep_ids) {
    state.all = keep_ids.reduce((acc, id) => {
      return { ...acc, [id]: state.all[id] }
    }, {})
  },
  OPEN_BID_MODAL(state, id) {
    state.bid_modal_id = id
  },
  CLOSE_BID_MODAL(state) {
    state.bid_modal_id = null
  },
  SET_BID_PRICE(state, v) {
    state.bid_price = v
  },
  SET_GROUP_META_COLLECTION(state, { name, data }) {
    state.groupMeta = {
      ...state.groupMeta,
      [name]: {
        events: data.collection,
        ids_token: data.ids_token,
        ...data.meta,
      },
    }
  },
}

export const actions = {
  async get({ commit }, { event_id, item_id }) {
    let res = await this.$api.get(`events/${event_id}/items/${item_id}`)
    if (res.data) {
      commit("UPDATE_ITEM", res.data)
      commit("autobids/UPDATE_ITEM", res.data, { root: true })
    }
    return res
  },

  async search({ commit }, { event_id, params }) {
    let res = await this.$api.get(`events/${event_id}/items`, params)
    if (res.data) commit("SET_COLLECTION", { name: "search", data: res.data })
    return res
  },

  async getBiddingTotal({ commit }, params) {
    let res = await this.$api.get("bidding_items/total", params)
    if (res.data) commit("SET_BIDDING_ITEMS_TOTALS", res.data)
    return res
  },

  async boxItems({ commit }, { event_id, box_id, params }) {
    let res = await this.$api.get(`events/${event_id}/box/${box_id}/items`, params)
    if (res.data) commit("SET_COLLECTION", { name: "box", data: res.data })
    return res
  },

  async save({ dispatch }, { event_id, item_id }) {
    let res = await this.$api.post(`events/${event_id}/items/${item_id}/like`)
    if (res.err?.response?.data?.error == "already_liked") dispatch("get", { event_id, item_id })
    return res
  },

  async unsave({}, { event_id, item_id }) {
    return await this.$api.delete(`events/${event_id}/items/${item_id}/like`)
  },

  async unsaveMultiple({}, ids) {
    return await this.$api.delete("likes", { ids })
  },

  async getHomeLikes({ commit }, params) {
    let res = await this.$api.get("likes", params)
    if (res.data) commit("SET_COLLECTION", { name: "home_likes", data: res.data })
    return res
  },

  async getCollection({ commit }, { name, params }) {
    commit("SET_COLLECTION_LOADING", { name, v: true })
    let res = await this.$api.get(name, params)
    if (res.data) commit("SET_COLLECTION", { name, data: res.data })
    commit("SET_COLLECTION_LOADING", { name, v: false })
    return res
  },

  async getCollectionEvents({ commit }, { name, params }) {
    let res = await this.$api.get(`${name}/events`, params)
    if (res.data) commit("SET_COLLECTION_EVENTS", { name, data: res.data })
  },

  async getFinished({ commit }, params) {
    let res = await this.$api.get("finished", params)
    if (res.data) commit("SET_GROUPED_COLLECTION", { name: params.type, data: res.data })
    return res
  },

  async getFinishedMeta({ commit }, params) {
    let res = await this.$api.get("finished/meta", params)
    if (res.data) commit("SET_GROUP_META", { type: params.type, data: res.data })
    return res
  },

  async getSold({ commit }, params) {
    let res = await this.$api.get("sold_items", params)
    if (res.data) commit("SET_GROUPED_COLLECTION", { name: "sold_items", data: res.data })
    return res
  },

  async getSoldMeta({ commit }, params) {
    let res = await this.$api.get("sold_items/events", params)
    if (res.data) commit("SET_GROUP_META_COLLECTION", { name: "sold_items", data: res.data })
    return res
  },

  async getPostNegotiationAvailable({ commit }, params) {
    let res = await this.$api.get("post_negotiations/available", params)
    if (res.data)
      commit("SET_GROUPED_COLLECTION", { name: "post_negotiation_available", data: res.data })
    return res
  },

  async getPostNegotiationAvailableMeta({ commit }, params) {
    let res = await this.$api.get("post_negotiations/available/events", params)
    if (res.data)
      commit("SET_GROUP_META_COLLECTION", { name: "post_negotiation_available", data: res.data })
    return res
  },

  async getPostNegotiationMeta({ commit }, params) {
    let res = await this.$api.get("post_negotiations/events", params)
    if (res.data) commit("SET_GROUP_META_COLLECTION", { name: "post_negotiation", data: res.data })
    return res
  },
}
