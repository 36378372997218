
import LogoutIcon from "vue-material-design-icons/Logout.vue"

export default {
  components: {
    LogoutIcon,
  },

  computed: {
    theme() {
      return this.$theme
    },
  },
}
