
import { ref, getDownloadURL } from "firebase/storage"

import DownloadIcon from "vue-material-design-icons/CloudDownload.vue"

const CACHE = {}
const NF_IMAGE = require("~/assets/images/no_img_item.png")

export default {
  components: {
    DownloadIcon,
  },

  props: {
    src: String,
    lazy: Boolean,
    iconSize: {
      type: Number,
      default: 110,
    },
    noImage: {
      type: String,
      default: NF_IMAGE,
    },
  },

  data() {
    return {
      loadedSrc: null,
      loadedImg: false,
      image: null,
    }
  },

  watch: {
    src: function () {
      this.getSrc()
    },
  },

  methods: {
    async getSrc() {
      const src = this.src
      if (!src) {
        this.loadedSrc = this.noImage
        return
      }

      if (src.match(/^http/)) {
        this.loadedSrc = src
        return
      }

      if (CACHE[src]) {
        this.loadedSrc = CACHE[src]
        return
      }

      try {
        const url = await getDownloadURL(ref(this.$fire.storage, src))
        this.loadedSrc = url
        CACHE[src] = url
      } catch (error) {
        this.loadedSrc = this.noImage
      }
    },

    loaded(e) {
      if (e.target) {
        this.$emit("size", {
          w: e.target.naturalWidth,
          h: e.target.naturalHeight,
        })
      }
      this.loadedImg = true
    },
  },

  fetch() {
    this.getSrc()
  },
}
