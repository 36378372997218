
export default {
  methods: {
    keyup(e) {
      if (e.key == "Escape") this.$emit("esc")
    },
  },

  mounted() {
    document.addEventListener("keyup", this.keyup)
  },

  beforeDestroy() {
    document.removeEventListener("keyup", this.keyup)
  },
}
