
import TickIcon from "vue-material-design-icons/CheckboxMarkedCircleOutline.vue"
import InfoIcon from "vue-material-design-icons/InformationOutline.vue"
import WarnIcon from "vue-material-design-icons/AlertOutline.vue"
import ErrorIcon from "vue-material-design-icons/AlertCircleOutline.vue"
import CloseIcon from "vue-material-design-icons/Close.vue"

export default {
  components: {
    TickIcon,
    InfoIcon,
    WarnIcon,
    ErrorIcon,
    CloseIcon,
  },

  props: {
    css: {
      type: String,
      default: "sticky ml-[15px] mr-[15px] lg:ml-[30px] lg:mr-[60px] top-24 my-4",
    },
  },

  computed: {
    messages: function () {
      return Object.values(this.$store.state.alerts.messages)
    },
  },

  methods: {
    close(id) {
      this.$store.dispatch("alerts/consume", id)
    },

    icon(alert) {
      switch (alert.type) {
        case "success":
          return "tick-icon"
        case "info":
          return "info-icon"
        case "warning":
          return "warn-icon"
        case "error":
          return "error-icon"
      }
    },

    backgroundClass(a) {
      switch (a.type) {
        case "success":
          return "bg-success-green-light"
        case "info":
          return "bg-info-blue-light"
        case "warning":
          return "bg-warning-amber-light"
        case "error":
          return "bg-error-red-light"
      }
    },

    iconClass(a) {
      switch (a.type) {
        case "success":
          return "text-success-green"
        case "info":
          return "text-info-blue"
        case "warning":
          return "text-warning-amber"
        case "error":
          return "text-error-red"
      }
    },

    textClass(a) {
      switch (a.type) {
        case "success":
          return "text-success-green-dark"
        case "info":
          return "text-info-blue-dark"
        case "warning":
          return "text-warning-amber-dark"
        case "error":
          return "text-error-red-dark"
      }
    },
  },
}
