export default ({ app, store, $config }, inject) => {
  const theme = $config.theme
  const v = store.state.version

  const getTerms = async () => {
    if (theme == "nj") {
      const res = await app.$api.get_tokenless(`terms?nj=true&v=${v}`)
      return res.data
    } else {
      const res = await app.$api.get_tokenless(`terms?v=${v}`)
      return res.data
    }
  }

  inject("getTerms", getTerms)
}
