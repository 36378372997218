
const states = {
  NONE: 0,
  RESERVE_80: 1,
  RESERVE_100: 2,
  ENDED: 3,
  SOLD: 4,
}

export default {
  props: {
    state: {
      type: Number,
      default: states.NONE,
    },
    size: {
      type: String,
      default: "md", // 'sm', 'lg'
    },
  },

  computed: {
    reserveMet: function () {
      return this.state == states.RESERVE_100
    },

    reserve80pct: function () {
      return this.state == states.RESERVE_80
    },

    ended: function () {
      return this.state == states.ENDED
    },

    activeState: function () {
      return this.state >= states.RESERVE_80 && this.state <= states.SOLD
    },

    statusText: function () {
      switch (this.state) {
        case states.RESERVE_80:
          return this.$t("item.banner.reserve_almost")
        case states.RESERVE_100:
          return this.$t("item.banner.reserve_met")
        case states.ENDED:
          return this.$t("app.ended")
        default:
          return ""
      }
    },

    themeColors() {
      return this.$theme == "nj"
        ? {
            "bg-status-close text-error-red": this.reserve80pct,
            "bg-error-red text-white": this.reserveMet,
            "bg-gray-550 text-white": this.ended,
          }
        : {
            "border border-accent bg-accent-dark text-white": this.reserve80pct,
            "border border-primary bg-gold-dark text-white": this.reserveMet,
            "bg-gray-750 text-white": this.ended,
          }
    },
  },
}
