
export default {
  layout: "default",

  name: "Error",
  props: {
    error: {
      type: Object,
      default: null,
    },
  },

  head() {
    return {
      title: this.message,
    }
  },

  computed: {
    message() {
      return this.error?.statusCode === 404
        ? this.$t("app.event_not_found")
        : this.$t("error.generic_error")
    },
  },
}
