export default async function ({ app, store, route, redirect }) {
  if (!store.getters.authenticated) {
    if (!["/soon", "/", `/${store.state.home_path}`].includes(route.path)) {
      store.commit("REDIRECT_PATH", route.fullPath)
    }
    if (store.getters.api_token) {
      return redirect(app.localePath("loading"))
    }
    let af = store.state.session_affiliate
    let query = af ? { [af]: null } : null
    return redirect({ path: app.localePath("login"), query })
  }

  // ensure user before showing app
  if (!store.state?.user) await store.dispatch("user")

  // TEMP hotfix: log out immediately if using inappropriate UI
  const e = store.state?.user?.eventer_codes
  const nj_user_not_nj_ui = e?.length == 1 && e[0] == "nj" && app.$theme != "nj"
  const no_affiliation_user_nj_ui = e && !e.find((c) => c == "nj") && app.$theme == "nj"

  if (nj_user_not_nj_ui || no_affiliation_user_nj_ui) {
    await app.$logout()
    setTimeout(() => {
      store.dispatch("alerts/create", {
        type: "error",
        message: app.i18n.t("error.auth_no_error"),
      })
    }, 500)
    return redirect("/login")
  }

  // check verified and approved
  var u = store.state?.user
  if (app.$userAccountVerified() === false) {
    let aff = app.$userAffiliate()
    return redirect({ path: "/wait", query: aff ? { aff: null } : null })
  }
}
