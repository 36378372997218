export const state = () => ({
  query: {},
})

export const mutations = {
  SAVE_QUERY(state, { path, query }) {
    state.query = {
      ...state.query,
      [path]: query,
    }
  },
  DELETE_QUERY(state, path) {
    const q = { ...state.query }
    delete q[path]
    state.query = q
  },
}

export const actions = {
  saveQuery({ commit }, { path, query }) {
    commit("SAVE_QUERY", { path, query })
  },

  deleteQuery({ commit }, { path }) {
    commit("DELETE_QUERY", path)
  },
}
