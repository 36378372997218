
import MenuIcon from "vue-material-design-icons/Menu.vue"

export default {
  components: {
    MenuIcon,
  },

  props: ["open"],
}
