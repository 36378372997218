import Vue from "vue"
import { updateItem } from "./index"

export const state = () => ({
  prices: {},
  items: {},
  // items forced to stay presentable until cleared
  forcePresent: {},
  single: false,
  modalOpen: false,
})

export const getters = {
  price: (state) => (id) => {
    return state.prices[id]
  },

  /**
   * "presentable" items don't always have valid autobids,
   *  just different from the current autobid.
   *  Ok to show in a confirmation modal
   */
  presentableItems: (state) => (ids) => {
    if (!ids) return []
    return Object.values(state.items).filter((item) => {
      const offer = state.prices[item.id]

      const forcePresent = state.forcePresent[item.id]

      const duplicateOffer =
        item.id in state.prices &&
        "current_user_auto_bid" in item &&
        item.current_user_auto_bid == offer

      const validBid = !(!state.prices[item.id] && !item.current_user_auto_bid)

      if (forcePresent || (!duplicateOffer && validBid)) {
        // restrict autobids to visible items only
        return ids.includes(item.id)
      }
    })
  },

  priceIsPresentable: (state) => (id) => {
    return id in state.prices
  },
}

export const mutations = {
  SET_PRICE(state, { price, item, force }) {
    Vue.set(state.prices, item.id, price)
    Vue.set(state.items, item.id, item)
    if (force) Vue.set(state.forcePresent, item.id, true)
  },
  REMOVE_PRICE(state, id) {
    Vue.delete(state.prices, id)
    Vue.delete(state.items, id)
    Vue.delete(state.forcePresent, id)
  },
  CLEAR_PRICES(state) {
    state.prices = {}
    state.items = {}
    state.forcePresent = {}
  },
  SHOW_MODAL(state, open) {
    state.modalOpen = open
  },
  SINGLE_AUTOBID(state, on) {
    state.single = on
  },
  UPDATE_ITEM(state, data) {
    if (data.id in state.items) {
      const item = updateItem(state, "items", data)
      if (item) state.items = { ...state.items, [data.id]: item }
    }
  },
}

export const actions = {
  async set({ commit }, payload) {
    commit("SET_PRICE", payload)
  },

  async remove({ commit }, id) {
    commit("REMOVE_PRICE", id)
  },

  async clear({ commit }) {
    commit("CLEAR_PRICES")
  },

  async show({ commit }) {
    commit("SHOW_MODAL", true)
  },

  async showWithItem({ commit }, { item }) {
    const price = item?.current_user_auto_bid || 0
    commit("CLEAR_PRICES")
    commit("SET_PRICE", { item, price, force: true })
    commit("SHOW_MODAL", true)
    commit("SINGLE_AUTOBID", true)
  },

  async hide({ state, commit }) {
    commit("SHOW_MODAL", false)
    if (state.single) {
      commit("SINGLE_AUTOBID", false)
      commit("CLEAR_PRICES")
    }
  },
}
