import cloneDeep from "lodash/cloneDeep"

export const state = () => ({
  requests: {},
  requestsMeta: {},
  event_categories: null,
  seller_upload_requests: null,
  items: {},
  itemDetails: {},
  itemInput: {},
})

export const mutations = {
  SET_EVENT_CATEGORIES(state, v) {
    state.event_categories = v
  },
  SET_REQUEST(state, v) {
    state.requests = { ...state.requests, [v.id]: v }
  },
  SET_REQUEST_METADATA(state, {id, data}) {
    state.requestsMeta = { ...state.requestsMeta, [id]: data }
  },
  SET_REQUESTS(state, v) {
    state.seller_upload_requests = v
  },
  SET_REQUEST_ITEMS(state, { id, data }) {
    state.items = { ...state.items, [id]: data }
  },
  SET_REQUEST_ITEM(state, data) {
    state.itemDetails[data.id] = data
  },
  SAVE_ITEM_INPUT(state, { data, requestId }) {
    state.itemInput[requestId] = data
  },
}

export const actions = {
  async getEventCategories({ commit }) {
    const res = await this.$api.get("event_categories")
    if (res.data) commit("SET_EVENT_CATEGORIES", res.data)
    return res
  },

  async getUploadRequests({ commit }) {
    const res = await this.$api.get("seller_upload_requests")
    if (res.data) commit("SET_REQUESTS", res.data)
    return res
  },

  async getRequest({ commit }, { id }) {
    const res = await this.$api.get(`seller_upload_requests/${id}`)
    if (res.data) commit("SET_REQUEST", res.data)
    return res
  },

  async getRequestMetadata({ commit }, { id }) {
    const res = await this.$api.get(`seller_upload_requests/${id}/meta`)
    if (res.data) commit("SET_REQUEST_METADATA", {id, data: res.data})
    return res
  },

  async createRequest({ commit }, { event_category_id }) {
    const res = await this.$api.post("seller_upload_requests", { request: { event_category_id } })
    if (res.data) commit("SET_REQUEST", res.data)
    return res
  },

  async updateRequest({ commit }, { id, event_category_id }) {
    const res = await this.$api.put(`seller_upload_requests/${id}`, {
      request: { event_category_id },
    })
    return res
  },

  async submitRequest(_, { id }) {
    return await this.$api.put(`seller_upload_requests/${id}/apply`)
  },

  async getRequestItems({ commit }, { seller_upload_request_id }) {
    const res = await this.$api.get("seller_upload_items", { seller_upload_request_id })
    if (res.data) commit("SET_REQUEST_ITEMS", { id: seller_upload_request_id, data: res.data })
    return res
  },

  async getRequestItem({ commit }, { id }) {
    const res = await this.$api.get(`seller_upload_items/${id}`)
    if (res.data) commit("SET_REQUEST_ITEM", res.data)
    return res
  },

  async validateRequestItem(_, { id, item }) {
    const res = await this.$api.post("seller_upload_items/validate", {
      item: {
        ...item,
        seller_upload_request_id: id,
      },
    })
    return res
  },

  async uploadRequestItem({ dispatch }, { id, item }) {
    const res = await this.$api.post("seller_upload_items", {
      item: {
        ...item,
        seller_upload_request_id: id,
      },
    })
    if (res.data) {
      dispatch("getRequestItems", { seller_upload_request_id: id })
      dispatch("getRequest", { id })
    }
    return res
  },

  async modifyRequestItem({ dispatch }, { id, item }) {
    const res = await this.$api.put(`seller_upload_items/${item.id}`, { item })
    if (res.data) dispatch("getRequestItems", { seller_upload_request_id: id })

    return res
  },

  async deleteRequestItem({ dispatch }, { id, itemId }) {
    const res = await this.$api.delete(`seller_upload_items/${itemId}`)
    if (res.data) {
      dispatch("getRequestItems", { seller_upload_request_id: id })
      dispatch("getRequest", { id })
    }
    return res
  },

  async addImagesToItem({}, { itemId, imageIds }) {
    return await this.$api.post(`seller_upload_items/${itemId}/images`, {
      image_id: imageIds
    })
  },

  async clearItemImages({}, { itemId, imageIds }) {
    return await this.$api.delete(`seller_upload_items/${itemId}/images`, {
      image_id: imageIds
    })
  },

  async saveItemInput({ commit }, { data, requestId }) {
    commit("SAVE_ITEM_INPUT", { data, requestId })
  },
}
