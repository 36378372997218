
export default {
  data() {
    return {
      hidden: null,
      visibilityChange: null,
    }
  },

  methods: {
    handleVisibilityChange() {
      if (!document[this.hidden]) {
        this.$pubsub.$emit("page:visible")
      }
    },
  },

  created() {
    if (typeof document.hidden !== "undefined") {
      // Opera 12.10 and Firefox 18 and later support
      this.hidden = "hidden"
      this.visibilityChange = "visibilitychange"
    } else if (typeof document.msHidden !== "undefined") {
      this.hidden = "msHidden"
      this.visibilityChange = "msvisibilitychange"
    } else if (typeof document.webkitHidden !== "undefined") {
      this.hidden = "webkitHidden"
      this.visibilityChange = "webkitvisibilitychange"
    }

    document.addEventListener(this.visibilityChange, this.handleVisibilityChange)
  },

  beforeDestroy() {
    document.removeEventListener(this.visibilityChange, this.handleVisibilityChange)
  },
}
