
export default {
  props: {
    open: Boolean,
    rightAlign: Boolean,
  },

  data() {
    return {
      isOpen: this.open,
    }
  },
}
