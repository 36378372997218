import moment from "moment"

moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    s: "1s",
    ss: "%ds",
    m: "1m",
    mm: "%dm",
    h: "1h",
    hh: "%dh",
    d: "1d",
    dd: "%dd",
    w: "1w",
    ww: "%dw",
    M: "1mo",
    MM: "%dmo",
    y: "1y",
    yy: "%dy",
  },
})

moment.updateLocale("ja", {
  relativeTime: {
    s: "%d秒",
    ss: "%d秒",
  },
})

const zone = moment.tz.guess()
if (zone) {
  console.log("Timezone detected:", zone)
}

export default ({ store }, inject) => {
  const updateMomentTime = function (diff) {
    if (!diff) diff = store.state.server_time_diff || 0
    if (Math.abs(diff) > 1500) {
      console.error(
        "UNRELIABLE CLIENT TIME! Device clock is",
        moment.duration(diff, "milliseconds").locale("en").humanize(),
        diff > 0 ? "slow." : "fast."
      )
      console.error("Using server time approximation.")
      moment.now = () => {
        return +new Date() + diff
      }
    } else {
      console.log("Using client time.")
      moment.now = () => {
        return +new Date()
      }
    }
  }

  const formatZ = function (datetime, format, zFormat) {
    if (zone == "Asia/Tokyo") return moment(datetime).format(format)

    return moment(datetime)
      .tz(zone)
      .format(format + " " + (zFormat || "(z)"))
  }

  inject("updateMomentTime", updateMomentTime)
  inject("formatTimeZ", formatZ)
}
