
export default {
  props: {
    item: Object,
  },

  computed: {
    diamondIcon() {
      switch (this.$theme) {
        case "nj":
          return require("~/assets/images/nj/diamond_primary.svg")
        default:
          return require("~/assets/images/icons/diamond_primary.svg")
      }
    },
  },
}
