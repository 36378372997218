
export default {
  props: {
    width: {
      type: Number,
      default: 128,
    },
  },
  computed: {
    iconSize() {
      return Math.max(20, Math.ceil(this.size / 2))
    },

    size() {
      return {
        width: `${this.width}px`,
        left: `-${this.width * 0.3}px`,
      }
    },
  },
}
