export default function ({ $device }) {
  $device.isTouchEnabled =
    "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0

  const isTouchDevice = () => {
    // Eliminate touchscreen-enabled desktop devices.
    // Some iPads identify as "desktop", complicating things.
    // - Alex
    const iOS = ($device.isIos || $device.isMacOS) && $device.isTouchEnabled
    const android = $device.isAndroid && $device.isTouchEnabled
    const mobileTablet = $device.isMobileOrTablet && $device.isTouchEnabled
    return iOS || android || mobileTablet
  }

  $device.isTouchDevice = isTouchDevice()
}
