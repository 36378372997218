import orderBy from "lodash/orderBy"

export const state = () => ({
  events: {},
  ids: [],
})

export const getters = {
  all: (state) => {
    return orderBy(
      state.ids.map((id) => state.events[id]),
      ["start_datetime"],
      ["desc"]
    )
  },
  ids: (state) => {
    return state.ids
  },
  item_ids: (state) => {
    return state.ids.map((id) => state.events[id]).map((e) => e.current_item_id)
  },
  match_item: (_, getters) => (item_id) => getters.item_ids.includes(item_id),
  event: (state) => (id) => {
    return state.events[id]
  },
}

export const mutations = {
  SET_LIVE_EVENTS(state, data) {
    state.ids = data.map((event) => event.id)
    state.events = data.reduce((acc, event) => {
      return { ...acc, [event.id]: event }
    }, {})
  },
  UPDATE_LIVE_EVENT(state, data) {
    const event = state.events[data.id]

    if (event) {
      state.events = {
        ...state.events,
        [data.id]: { ...event, ...data },
      }
    }
  },
  ADD_LIVE_EVENT(state, data) {
    state.ids = [...state.ids, data.id]
    state.events = {
      ...state.events,
      [data.id]: data,
    }
  },
  REMOVE_LIVE_EVENT(state, data) {
    const id = data.id
    const e = state.events[id]
    if (!e) return

    state.events = {
      ...state.events,
      [id]: { ...e, status: 2 },
    }

    state.ids = state.ids.filter((e) => e != id)
  },
}

export const actions = {}
