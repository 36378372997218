
import AudioOnIcon from "vue-material-design-icons/VolumeHigh.vue"
import AudioOffIcon from "vue-material-design-icons/VolumeOff.vue"

export default {
  components: {
    AudioOnIcon,
    AudioOffIcon,
  },

  props: {
    on: Boolean,
    disabled: Boolean,
    size: Number,
  },

  computed: {
    iconSize() {
      return Math.max(20, Math.ceil(this.size / 2))
    },
  },
}
