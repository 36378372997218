import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import { getStorage } from "firebase/storage"

const firebaseConfig = {
  apiKey: "AIzaSyDvjVkQHZMLj3H4YxqaXDxvQWT_hRy-6xc",
  authDomain: "net-auctions.firebaseapp.com",
  databaseURL: "https://net-auctions.firebaseio.com",
  projectId: "net-auctions",
  storageBucket: "net-auctions.appspot.com",
  messagingSenderId: "160742697928",
  appId: "1:160742697928:web:3694225d05ed917ea1aefd",
}

const firebaseApp = initializeApp(firebaseConfig)
const analytics = getAnalytics(firebaseApp)
const storage = getStorage(firebaseApp)

export default ({ store, app }, inject) => {
  inject("fire", { app: firebaseApp, analytics, storage })

  const login = async (login_id, password, persist) => {
    const { data, err } = await app.$api.post_tokenless("login", {
      login_id,
      password,
      remember_me: persist == "local" ? true : false,
      // remember_me: "test_10_seconds",
    })

    if (err) return { err }

    if (persist == "local") {
      localStorage.setItem("api_token", data.api_token)
      store.commit("SET_API_TOKEN_LOCAL", data.api_token)
    } else {
      sessionStorage.setItem("api_token", data.api_token)
      store.commit("SET_API_TOKEN_SESSION", data.api_token)
    }

    return await store.dispatch("user")
  }

  const logout = async () => {
    await app.$api.delete("logout")
    store.commit("CLEAR_API_TOKEN")
  }

  inject("login", login)
  inject("logout", logout)
}
