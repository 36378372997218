import axios from "axios"
import debounce from "lodash.debounce"
import { pubsub } from "./pubsub"

export default (context, inject) => {
  const authError = debounce(() => {
    pubsub.$emit("auth:error")
  }, 500)

  const handleError = (e, emit = true) => {
    console.error(JSON.stringify(e))
    if (e.response?.status == 403 && emit) authError()
    return { err: e }
  }

  const api_token = () => {
    return context.app.store.getters.api_token
  }

  const api_headers = async (content_type) => {
    const token = api_token()

    if (!token) throw new Error("Token not found")

    return {
      "Content-Type": content_type || "application/json",
      "Content-Language": context.app.i18n.locale,
      Authorization: `Bearer ${token}`,
      "X-Version": context?.app?.store?.state?.version,
      "X-Pathname": window.location.pathname,
      "X-Api-Version": context?.route?.query?.api,
    }
  }

  const api_headers_tokenless = (content_type) => {
    return {
      "Content-Type": content_type || "application/json",
      "Content-Language": context.app.i18n.locale,
    }
  }

  const get = async (endpoint, params, prefix = "api/", auth = true, emitError) => {
    try {
      params = { ...params, user_id: context.store.state.user?.id }
      const res = await axios({
        method: "GET",
        url: `${context.$config.phxUrl}/${prefix}${endpoint}`,
        params,
        crossdomain: true,
        headers: auth ? await api_headers() : api_headers_tokenless(),
      })
      return res
    } catch (err) {
      return handleError(err, emitError)
    }
  }

  const set = async (method, endpoint, data, auth = true, content_type, emitError) => {
    try {
      const res = await axios({
        method: method,
        url: `${context.$config.phxUrl}/api/${endpoint}`,
        data,
        params: { user_id: context.store.state.user?.id },
        crossdomain: true,
        headers: auth ? await api_headers(content_type) : api_headers_tokenless(content_type),
      })
      return res
    } catch (err) {
      return handleError(err, emitError)
    }
  }

  const del = async (endpoint, params, emitError) => {
    try {
      params = { ...params, user_id: context.store.state.user?.id }
      let headers = await api_headers()
      const res = await axios({
        method: "DELETE",
        url: `${context.$config.phxUrl}/api/${endpoint}`,
        params,
        crossdomain: true,
        headers,
      })
      return res
    } catch (err) {
      return handleError(err, emitError)
    }
  }

  const get_tokenless = async (endpoint, params, emitError) => {
    return await get(endpoint, params, "api/", false, emitError)
  }

  const put = async (endpoint, data, emitError) => {
    return await set("PUT", endpoint, data, true, emitError)
  }

  const post = async (endpoint, data, emitError) => {
    return await set("POST", endpoint, data, true, emitError)
  }

  const post_tokenless = async (endpoint, data, emitError) => {
    return await set("POST", endpoint, data, false, emitError)
  }

  const post_multipart_tokenless = async (endpoint, data, emitError) => {
    return await set("POST", endpoint, data, false, "multipart/form-data", emitError)
  }

  inject("api", {
    get,
    get_tokenless,
    put,
    post,
    post_tokenless,
    post_multipart_tokenless,
    delete: del,
    api_token,
  })
}
