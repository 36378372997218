import AgoraRTC from "agora-rtc-sdk-ng"
import { v4 as uuidv4 } from "uuid"

const clients = {}
const subscriptions = {}
const MAX_RETRIES = 10

let retries = MAX_RETRIES
let retryTimer = null

export const state = () => ({
  viewer_count: {},
  connections: {},
  item: {},
  modals: {},
  next_item: {},
  whole_timeline: {},
  autoplay_blocked: true,
})

const handleUserPublished = (channel) => async (user, mediaType) => {
  const res = await clients[channel].subscribe(user, mediaType)

  if (mediaType === "video") {
    if (document.getElementById("video")) {
      user.videoTrack.play("video")
    }
  }

  if (mediaType === "audio") user.audioTrack.play()

  if (!subscriptions[channel]) subscriptions[channel] = {}
  subscriptions[channel][mediaType] = res
}

const handleUserUnpublished = (channel) => async (user, mediaType) => {
  await clients[channel].unsubscribe(user, mediaType)
  subscriptions[channel][mediaType] = null
}

const _stopStream = async (channel) => {
  if (clients[channel]) {
    subscriptions[channel] = {}
    await clients[channel].leave()
  }
}

const connectToAgora = async (client, app_id, channel, token, prefix, uid) => {
  try {
    await client.join(app_id, channel, token, `${prefix}${uid}`)
    return true
  } catch (e) {
    if (e.message.match(/key expired/i)) {
      alert("Expired")
    } else if (e.message.match(/invalid token/i)) {
      alert("Invalid Token")
    } else {
      throw e
    }

    return false
  }
}

export const getters = {
  audioIsPlaying: () => (channel) => {
    if (!channel) {
      console.warn("audioIsPlaying: Invalid channel provided.")
      return false
    }
    return subscriptions[channel]?.audio?.isPlaying
  },

  feedIsPlaying: () => (channel) => {
    if (!channel) {
      console.warn("feedIsPlaying: Invalid channel provided.")
      return false
    }
    return subscriptions[channel]?.video?.isPlaying
  },
}

export const mutations = {
  SET_VIEWER_COUNT(state, { id, count }) {
    state.viewer_count = {
      ...state.viewer_count,
      [id]: count,
    }
  },
  SET_CONNECTION(state, { channel, value }) {
    state.connections = {
      ...state.connections,
      [channel]: value,
    }
  },
  SET_NEXT_ITEM(state, data) {
    state.next_item = { [data.id]: data }
  },
  SET_WHOLE_TIMELINE(state, { item_id, timeline }) {
    state.whole_timeline = { [item_id]: timeline }
  },
  CLEAR_WHOLE_TIMELINE(state, item_id) {
    const wt = { ...state.whole_timeline }
    delete wt[item_id]
    state.whole_timeline = wt
  },
  BLOCK_AUTOPLAY(state, val) {
    state.autoplay_blocked = val
  },
}

export const actions = {
  async startStream({ commit, dispatch, rootState }, channel) {
    if (retryTimer) clearTimeout(retryTimer)

    await _stopStream(channel)
    commit("SET_CONNECTION", { channel, value: false })

    const prefix = uuidv4()
    const res = await this.$api.get("agora/token", { channel, prefix })

    if (res.err) {
      if (res.err.message?.match(/network error/i)) {
        if (retries > 0) {
          --retries
          retryTimer = setTimeout(() => {
            dispatch("startStream", channel)
          }, 500)
        } else {
          console.error("Start Agora stream failed - network error.")
        }
      }
      return
    }

    retries = MAX_RETRIES

    const { app_id, token } = res.data
    const client = AgoraRTC.createClient({ mode: "live", codec: "vp8" })
    clients[channel] = client
    await client.setClientRole("audience")

    client.on("user-published", (user, mediaType) => {
      handleUserPublished(channel)(user, mediaType)
    })

    client.on("user-unpublished", (user, mediaType) => {
      handleUserUnpublished(channel)(user, mediaType)

      // Only leave if both audio and video are off
      if (!subscriptions[channel]?.video && !subscriptions[channel]?.audio) {
        _stopStream(channel)
        commit("SET_CONNECTION", { channel, value: false })
      }
    })

    const joined = await connectToAgora(
      client,
      app_id,
      channel,
      token,
      prefix,
      rootState.user.firebase_uid
    )
    if (joined) commit("SET_CONNECTION", { channel, value: true })
  },

  async stopStream({ commit }, channel) {
    await _stopStream(channel)
    commit("SET_CONNECTION", { channel, value: false })
  },

  async playVideo({ state, dispatch }, channel) {
    if (state.connections[channel]) {
      subscriptions[channel]?.video?.play("video")
    } else {
      dispatch("startStream", channel)
    }
  },

  async pauseVideo({ state }, channel) {
    if (state.connections[channel]) {
      subscriptions[channel]?.video?.stop()
    }
  },

  async playAudio({ state, dispatch }, channel) {
    if (state.connections[channel]) {
      subscriptions[channel]?.audio?.play()
    } else {
      dispatch("startStream", channel)
    }
  },

  async pauseAudio({ state }, channel) {
    if (state.connections[channel]) {
      subscriptions[channel]?.audio?.stop()
    }
  },

  interact({ commit }) {
    commit("BLOCK_AUTOPLAY", false)
  },
}
