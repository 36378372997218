
import ErrorIcon from "vue-material-design-icons/AlertCircleOutline.vue"
import CloseIcon from "vue-material-design-icons/Close.vue"

export default {
  components: {
    ErrorIcon,
    CloseIcon,
  },

  data() {
    return {
      errors: [],
    }
  },

  computed: {
    id() {
      return this.$store.state.item.bid_modal_id
    },

    item() {
      return this.$store.state.item.all[this.id]
    },

    current_price() {
      return this.item?.current_price || 0
    },

    bid_price() {
      return this.$store.state.item?.bid_price
    },
  },

  watch: {
    current_price(n, o) {
      if (n != o) this.close()
    },

    $route(to, from) {
      if (to != from) this.close()
    },
  },

  methods: {
    close() {
      this.$store.commit("item/CLOSE_BID_MODAL")
    },
  },

  mounted() {
    document.body.classList.add("modal-open")
  },

  beforeDestroy() {
    document.body.classList.remove("modal-open")
  },
}
