import Vue from "vue"

var msg_uid = 1

export const state = () => ({
  messages: {},
})

export const getters = {
  sameMessage: (state) => (msg) => {
    return Object.values(state.messages)
      .filter((e) => e.type === msg.type)
      .find((e) => e.message == msg.message)
  },
}

export const mutations = {
  SET_MESSAGE(state, data) {
    Vue.set(state.messages, data.id, data)
  },
  CONSUME_MESSAGE(state, id) {
    Vue.delete(state.messages, id)
  },

  CLEAR_MESSAGES(state, { soft }) {
    if (soft) {
      // only clear messages without an expiry
      for (const id in state.messages) {
        if (!state.messages[id].consumer) {
          Vue.delete(state.messages, id)
        }
      }
    } else {
      Object.values(state.messages)
        .filter((e) => e.consumer)
        .forEach((e) => {
          clearTimeout(e.consumer)
        })
      state.messages = {}
    }
  },
}

export const actions = {
  /**
   * Create a sticky alert on the UI
   *
   * @param {Object} vuex
   * @param {Object} obj
   * @param {string} [obj.type] - "success", "info", "warning", "error"
   * @param {string} obj.message
   * @param {string} [obj.actionText] - Action button text
   * @param {string} [obj.actionPath] - Local URL
   * @param {int} [obj.duration] - Seconds before message is consumed
   * @param {boolean} [obj.canMerge] - Identical messages will not be duplicated
   */
  create(
    { getters, dispatch, commit },
    { type = "info", message, actionText, actionPath, duration = 0, canMerge = true }
  ) {
    let msg = {
      message,
      actionText,
      actionPath,
      merge: canMerge,
      duration,
      type: type,
      count: 1,
    }

    let id = msg_uid++

    // handle merges
    let prevMsg = getters.sameMessage(msg)
    if (canMerge && prevMsg) {
      id = prevMsg.id
      // reset timer
      if (prevMsg.consumer) {
        clearTimeout(prevMsg.consumer)
      }
      msg = { ...msg, count: prevMsg.count + 1 }
    }

    let consumer
    if (duration > 0) {
      consumer = setTimeout(() => dispatch("consume", id), duration * 1000)
    }
    commit("SET_MESSAGE", { ...msg, id, consumer })
  },

  consume({ commit }, id) {
    commit("CONSUME_MESSAGE", id)
  },

  clear({ commit }, params) {
    commit("CLEAR_MESSAGES", params)
  },
}
