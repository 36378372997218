
export default {
  middleware: "validated",
  name: "Pending",

  data() {
    return {
      style: {},
    }
  },

  methods: {
    onResize() {
      this.style = { minHeight: `${window.innerHeight}px` }
    },
  },

  mounted() {
    window.addEventListener("resize", this.onResize, false)
    this.onResize()
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize, false)
  },
}
