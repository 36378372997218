
export default {
  props: ["open"],

  computed: {
    unread_notification_count() {
      return this.$store.state.unread_notification_count || 0
    },

    unread_news_count() {
      return this.$store.state.unread_news_count || 0
    },

    unread() {
      return this.unread_notification_count + this.unread_news_count
    },

    navItems() {
      return [
        {
          title: this.$t("app.auction"),
          id: "live-link",
          to: "/auction",
        },
        {
          title: this.$t("news.title"),
          id: "news-link",
          to: this.unread_notification_count ? "/news/notifications" : "/news",
        },
        {
          title: this.$t("profile.likes" + this.$theme_suffix),
          id: "likes-link",
          to: "/likes",
        },
        {
          title: this.$t("memos.nav"),
          id: "memos-link",
          to: "/memos",
        },
        {
          title: this.$t("profile.bids"),
          id: "bids-link",
          to: "/bids",
        },
        {
          title: this.$t("profile.purchases"),
          id: "wins-link",
          to: "/wins",
        },
        {
          title: this.$t("app.crumbs.mypage"),
          id: "profile-link",
          to: "/mypage",
        },
      ].filter((e) => {
        if (this.$feature(e.id.split("-")[0])) {
          return true
        } else {
          return false
        }
      })
    },
  },

  methods: {
    activePath(path) {
      return this.$route.path.split("/").includes(path.split("/")[1])
    },

    linkImg(nav) {
      return this.activePath(nav.to) ? nav.activeImage : nav.image
    },
  },
}
