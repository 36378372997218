export default ({ app, store, $formatPrice, $currencySymbolByID, $formatCustomPrice }, inject) => {
  const getLocalizedName = (obj) => {
    if (!obj) return "---"
    let lang = app.i18n.locale == "ja" ? "ja" : "en"
    return obj.name[lang]
  }

  const currencyFromMetadata = (event_id, item_id) => {
    let use_carat = false
    const item = store.state.item.all[item_id]
    const event = store.state.auction.event[event_id]

    if (item) use_carat = item.use_carat_price
    else if (event_id) use_carat = !!event?.jewelry_auction

    return use_carat ? "@" : $currencySymbolByID(item?.currency || event?.currency)
  }

  const attributeNameFromKey = (key) => {
    let i18n = app.i18n
    switch (key) {
      case "category_id":
        return i18n.t("item.attribute.category")
      case "subcategory_id":
        return i18n.t("item.attribute.subcategory")
      case "brand_id":
        return i18n.t("item.attribute.brand")
      case "box_id":
      case "box_no":
        return i18n.t("item.attribute.box_code")
      case "comment":
      case "misc":
        return i18n.t("item.attribute.comment")
    }
    return i18n.t(`item.attribute.${key}`)
  }

  const getAttribute = (key, value, event_id, item_id) => {
    const item = store.state.item.all[item_id]
    const event = store.state.auction.event[event_id]
    const currency = item?.currency || event?.currency

    switch (key) {
      case "category_id":
      case "subcategory_id":
        return getLocalizedName(value)

      case "brand_id":
        return value?.name || "---"

      case "price":
        return $formatCustomPrice(value || 0, currency)

      case "shipping_price":
        if (value == null) return ""
        if (value == 0) return app.i18n.t("app.free_shipping")
        if (value < 0) return app.i18n.t("app.unable_to_deliver")
        return $formatCustomPrice(value, currency)

      case "start_price":
        return currencyFromMetadata(event_id, item_id) + $formatPrice(value, currency)
    }
    return value
  }

  inject("revisions", { attributeNameFromKey, getAttribute })
}
