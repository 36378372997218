
export default {
  props: {
    status: {
      type: Number,
      required: true,
    },
    mini: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    statusObj() {
      if (!this.status) {
        return {
          text: "Waiting...",
          color: "bg-error-red",
        }
      }

      if (this.status == 10) {
        return {
          text: this.$t("live_event.before_start"),
          color: "bg-accent",
        }
      }

      if (this.status == 11) {
        return {
          text: this.$t("live_event.bidding_open"),
          color: "bg-primary",
        }
      }

      return {
        text: this.$t("live_event.ended"),
        color: "bg-gray-550",
      }
    },
  },
}
