
import { logEvent } from "firebase/analytics"

import TickIcon from "vue-material-design-icons/CheckboxMarkedCircleOutline.vue"
import ErrorIcon from "vue-material-design-icons/AlertCircle"
import CashStackIcon from "vue-material-design-icons/CashMultiple.vue"

export default {
  components: {
    CashStackIcon,
    TickIcon,
    ErrorIcon,
  },

  props: {
    disabled: {
      type: Boolean,
    },
    size: {
      type: Object,
    },
    event_id: {
      type: [Number, String],
      required: true,
    },
    item_id: {
      type: [Number, String],
      required: true,
    },
    current_price: {
      type: Number,
      required: true,
    },
    price: {
      type: Number,
      required: true,
    },
    expand: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: "primary",
    },
    track: {
      type: String,
      required: true,
    },
    live_current: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      bid_successful: false,
      bid_error: false,
      countdown_zero: false,
    }
  },

  computed: {
    cashIcon() {
      let name = this.theme == "special" ? "brown" : "white"
      return require(`~/assets/images/icons/cash_stack_${name}.svg`)
    },

    is_bidding() {
      return this.$store.state.auction?.is_bidding
    },

    bid_modal_open() {
      return this.$store.state.item.bid_modal_id
    }
  },

  methods: {
    bidOrConfirm() {
      if (this.$userBidConfirm() && !this.live_current && !this.bid_modal_open) {
        this.$store.commit("item/OPEN_BID_MODAL", this.item_id)
      } else {
        this.bid()
      }
    },

    async bid() {
      if (this.bidResponseTimeout) clearTimeout(this.bidResponseTimeout)
      this.bid_successful = false
      this.bid_error = false

      let payload = {
        item_id: this.item_id,
        event_id: this.event_id,
        price: this.price,
      }

      const current_price = this.current_price

      const { data, err } = await this.$store.dispatch("auction/bid", payload)

      if (data) {
        logEvent(this.$fire.analytics, "bid", {
          value: `${payload.event_id}/${payload.item_id}`,
          price: current_price,
          bid_amount: payload.price,
          source: this.$store.state.source,
          track: this.track,
        })

        this.bid_successful = true

        this.bidResponseTimeout = setTimeout(() => {
          this.bid_successful = false
        }, 1000)
      }

      if (err) {
        this.$pubsub.$emit("bid:error")

        this.$emit("error", err.response?.data?.error)

        this.bid_error = true

        this.bidResponseTimeout = setTimeout(() => {
          this.bid_error = false
        }, 1000)

        logEvent(this.$fire.analytics, "bid_error", {
          value: `${payload.event_id}/${payload.item_id}`,
          price: current_price,
          bid_amount: payload.price,
          source: this.$store.state.source,
          track: this.track,
          status: err.response?.status,
          data: JSON.stringify(err.response?.data),
        })
      }
    },

    countdownZero({ id, zero }) {
      if (id == this.item_id) this.countdown_zero = zero
    },
  },

  created() {
    this.$pubsub.$on("countdown_zero", this.countdownZero)
  },

  beforeDestroy() {
    this.$pubsub.$off("countdown_zero", this.countdownZero)
  },
}
