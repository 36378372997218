
import { socket } from "~/utils/socket"

export default {
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      connected: false,
      attempts: 0,
    }
  },

  computed: {
    next_item() {
      return this.$store.state.live.next_item[this.id]
    },

    get_item() {
      return !this.next_item
    },
  },

  methods: {
    params() {
      if (this.connected) {
        // Re-connected should always fetch the item
        return { get_item: true }
      } else {
        return { get_item: this.get_item }
      }
    },

    onItem(data) {
      this.$pubsub.$emit("item:updated", data)
      this.$emit("item", data)
    },

    onErr(data) {
      this.$pubsub.$emit("item:error", { id: this.id, ...data })
      this.$emit("err", data)
    },

    onTimeout() {
      console.log("Join Timeout")
      this.$pubsub.$emit("socket_needs_reconnect")
    },

    connectItem() {
      this.channel = socket.channel(`item:${this.id}`, this.params)
      this.channel.on("item", this.onItem)
      this.channel.on("error", this.onErr)
      this.channel.on("reset", this.onReset)
      this.channel
        .join()
        .receive("ok", () => (this.connected = true))
        .receive("error", () => console.log("Join Error"))
        .receive("timeout", () => this.onTimeout)
      this.timer = setTimeout(this.checkForData, 2000)
    },

    checkForData() {
      this.attempts++
      if (this.$store.state.item.all[this.id]) return
      if (this.$store.state.item.err[this.id]) return
      if (this.attempts > 3) return
      this.fetchItem()
      this.timer = setTimeout(this.checkForData, 2000)
    },

    fetchItem() {
      this.channel
        .push("get")
        .receive("ok", this.onItem)
        .receive("error", this.onErr)
        .receive("timeout", this.checkForData)
    },

    onReset() {
      this.$pubsub.$emit("item:updated", { id: this.id, reset: true })
      this.fetchItem()
    },
  },

  created() {
    this.connectItem()
  },

  beforeDestroy() {
    this.channel.leave()
    if (this.timer) clearTimeout(this.timer)
    this.$store.commit(`item/CLEAR_ITEM_ERR`, this.id)
  },
}
