import axios from "axios"

export const state = () => ({
  images: {},
})

export const mutations = {
  SET_IMAGES(state, v) {
    state.images = v
  },
}

export const actions = {
  async getUploadImages({ commit }, params) {
    const res = await this.$api.get("seller_upload_images", params)
    if (res.data) commit("SET_IMAGES", res.data)
    return res
  },

  async upload({}, { file, onProgress }) {
    const data = {
      content_type: file.type,
    }
    const res = await this.$api.post("seller_upload_images/signed", data)
    if (res.err) return res

    const request = axios.create({
      crossdomain: true,
      headers: { "Content-Type": file.type },
    })

    try {
      // Upload file to signed URL
      await request.put(res.data.signed_url, file, {
        onUploadProgress: (e) => {
          onProgress(Math.round((e.loaded * 100) / e.total))
        },
      })
    } catch (e) {
      return { err: e }
    }

    return res
  },

  async complete({}, { signed_data }) {
    return await this.$api.post("seller_upload_images/complete", {
      signed_data: signed_data,
    })
  },

  async deleteImage({}, imageId) {
    return await this.$api.delete(`seller_upload_images/${imageId}`)
  },
}
