export const state = () => ({
  socket: [],
})

export const mutations = {
  LOG(state, data) {
    state.socket = [...state.socket, data]
  },
}

export const actions = {
  async log({ commit }, data) {
    commit("LOG", data)
  },
}
