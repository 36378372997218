export default ({}, inject) => {
  const currencies = {
    jpy: { symbol: "¥", isoString: "JPY", variant: "円" },
    usd: { symbol: "$", isoString: "USD", denominator: 100 },
    cny: { symbol: "¥", isoString: "CNY", denominator: 100 },
  }

  const currencySymbolByID = (code) => {
    return currencies[code || "jpy"].symbol || "¥"
  }

  const currencyVariantByID = (code) => {
    let c = currencies[code || "jpy"]
    return c.variant || c.isoString || "円"
  }

  const currencyHasVariant = (code) => {
    return code in currencies && currencies[code].variant
  }

  const currencySymbol = ({ item, canUseCarat = true, useVariant }) => {
    if (item.use_carat_price && canUseCarat) return "@"
    if (useVariant) return currencyVariantByID(item.currency)
    return currencySymbolByID(item.currency)
  }

  const currencyIsoString = ({ item, code, canUseCarat = true }) => {
    if (item?.use_carat_price && canUseCarat) return "@"
    return currencies[item?.currency || code || "jpy"].isoString
  }

  const systemToCurrencyPrice = (p, c) => {
    let d = currencies[c || "jpy"].denominator
    if (!!d) p /= d
    return p
  }

  const currencyToSystemPrice = (p, c) => {
    let d = currencies[c || "jpy"].denominator
    if (!!d) p *= d
    return p
  }

  const formatItemPrice = (item, key) => {
    if (!item) return ""
    // if not a won item, replace final_price with current_price
    if (key === "final_price" && ![5, 8].includes(item.status)) key = "current_price"
    if (!(key in item) || Number.isNaN(item[key])) return ""

    const canUseCarat = ["current_price", "start_price"].includes(key)
    let c = currencySymbol({ item, canUseCarat })
    let p = formatPrice(item[key] || 0, item.currency)
    return c + p
  }

  const formatCustomPrice = (price, currency, useVariant) => {
    const variant = useVariant && currencyHasVariant(currency)
    let c = variant ? currencyVariantByID(currency) : currencySymbolByID(currency)
    let p = formatPrice(price, currency)
    return variant ? p + c : c + p
  }

  const formatPrice = (p, c) => {
    if (Number(p) != p) return ""
    // Some currencies are stored in their fractional unit (e.g. cents)
    let d = currencies[c || "jpy"].denominator
    if (!!d) p = (p / d).toFixed(2)
    return p.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  inject("formatPrice", formatPrice)
  inject("formatItemPrice", formatItemPrice)
  inject("formatCustomPrice", formatCustomPrice)
  inject("currencySymbol", currencySymbol)
  inject("currencySymbolByID", currencySymbolByID)
  inject("currencyVariantByID", currencyVariantByID)
  inject("currency", currencyIsoString)
  inject("systemToCurrencyPrice", systemToCurrencyPrice)
  inject("currencyToSystemPrice", currencyToSystemPrice)
}
