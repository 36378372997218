
import TickIcon from "vue-material-design-icons/CheckboxMarkedCircleOutline.vue"
import CashStackIcon from "vue-material-design-icons/CashMultiple.vue"
import ChevronRight from "vue-material-design-icons/ChevronRight.vue"
import WarningIcon from "vue-material-design-icons/AlertOutline.vue"
import ChevronExpand from "vue-material-design-icons/UnfoldMoreHorizontal.vue"
import ChevronCollapse from "vue-material-design-icons/UnfoldLessHorizontal.vue"
import { Howl } from "howler"
import { checkBiddable } from "~/utils/biddable"

export default {
  components: {
    CashStackIcon,
    ChevronRight,
    TickIcon,
    WarningIcon,
    ChevronExpand,
    ChevronCollapse,
  },

  props: {
    event: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      userWantsAudio: false,
      audioIsPlaying: false,
      bidPrice: 0,
      expanded: true,
      was_collapsed: false,
      loadingMeta: true,
      biddable: {},
    }
  },

  computed: {
    event_id() {
      return this.event.id
    },

    channel() {
      return `event:${this.event_id}`
    },

    current_item_id() {
      return this.event.current_item_id
    },

    item() {
      return this.$store.state.item.all[this.current_item_id]
    },

    connection() {
      return this.$store.state.live.connections[this.channel]
    },

    current_price() {
      return this.item?.current_price || 0
    },

    userIsWinning: function () {
      return !!this.item?.highest_bid_flag
    },

    reserveState() {
      return this.item.reserve_state || 0
    },

    item_id() {
      return this.item?.id
    },

    bidding_disabled() {
      return this.userIsWinning || !this.bidPrice || this.biddable?.error
    },

    ended() {
      return this.item && ![10, 11].includes(this.item?.status)
    },

    audio_streaming() {
      return this.event.audio_streaming
    },

    audioBlocked() {
      return this.$store.state.live.autoplay_blocked
    },

    hidden() {
      const id = parseInt(this.$route.params.item)
      const path = this.$route.path
      const mo = this.$device.isMobile
      const ids = this.$store.getters["live_events/item_ids"]

      // Hide when auction route with /live
      if (path.match(/\/auction/) && path.match(/\/live/)) return true

      // Hide when it's an auction route with an item
      if (mo && path.match(/\/auction/) && id) return true

      // Hide when current item is live item
      if (!mo && path.match(/\/auction/) && ids?.includes(id)) return true

      // Don't hide unless a condition is matched
      return false
    },

    bid_config() {
      return this.$store.state.item.bid_configs[this.item?.event_id]
    },
  },

  methods: {
    tick() {
      this.updateAudioPlaying()
      this.updateBiddable()
    },

    updateBiddable() {
      this.biddable = checkBiddable(this.event, this.event, this.item)
    },

    updateAudioPlaying() {
      this.audioIsPlaying = this.$store.getters["live/audioIsPlaying"](this.channel)
    },

    toggleAudio() {
      if (this.audioBlocked) {
        this.interact()
        if (!this.audioIsPlaying) this.startAudio()
        return
      }
      if (this.audioIsPlaying) {
        this.userWantsAudio = false
        this.stopAudio()
      } else {
        this.startAudio()
      }
    },

    startAudio() {
      this.userWantsAudio = true
      this.$store.dispatch(`live/playAudio`, this.channel)
    },

    stopAudio() {
      this.$store.dispatch(`live/pauseAudio`, this.channel)
    },

    setBidRestrictions() {
      this.bidPrice =
        this.current_price + this.$bidRules.liveStep(this.current_price, this.bid_config)
    },

    endEvent(data) {
      if (this.event_id == data.id && data.status == 2) {
        if (this.connection) {
          this.userWantsAudio = false
          this.stopAudio()
        }
      }
    },

    bidSound() {
      this.playSound("/bid.mp3")
    },

    playSound(path) {
      if (!this.$userSounds()) return
      if (!this.connection) return
      const sound = new Howl({ src: [path], volume: 0.5, onplayerror: this.playError })
      const blocked = this.$store.state.live.autoplay_blocked
      if (!blocked) {
        sound.play()
      }
    },

    playError(id, error) {
      console.warn(`Play sound ${id} failed.\n${error}`)
    },

    expandDesktop() {
      if (window.innerWidth < 640 && !this.expanded) {
        this.expanded = true
        this.was_collapsed = true
      }

      if (window.innerWidth >= 640 && this.was_collapsed) {
        this.expanded = false
        this.was_collapsed = false
      }
    },

    interact() {
      if (this.$store.state.live.autoplay_blocked) {
        this.$store.dispatch("live/interact")
      }
    },

    expand() {
      this.expanded = true
      this.interact()
    },

    collapse() {
      this.expanded = false
      this.interact()
    },
  },

  created() {
    this.$pubsub.$on("live_events:remove", this.endEvent)
    this._bidSound = this.$lodash.debounce(this.bidSound, 200)
    window.addEventListener("resize", this.expandDesktop)
    window.addEventListener("beforeunload", this.stopAudio)
    this.tick()
    this.$pubsub.$on("tick", this.tick)

    // Don't auto play audio when developing
    // I wish this was the default but I can
    // at least not need to consume
    // Agora when developing
    if (window.location.host.match(/localhost/)) return
    this.startAudio()
  },

  beforeDestroy() {
    this.$pubsub.$off("tick", this.tick)
    this.$pubsub.$off("live_events:remove", this.endEvent)
    this.stopAudio()
    window.removeEventListener("beforeunload", this.stopAudio)
    window.removeEventListener("resize", this.expandDesktop)
  },

  watch: {
    item(v) {
      if (v) this.setBidRestrictions()
    },

    current_price(v) {
      this.setBidRestrictions()
      if (v && this.item?.status == 11) this._bidSound()
    },

    ended(v) {
      if (v) this.playSound("/finish.mp3")
    },

    audio_streaming(v) {
      if (v && this.userWantsAudio) {
        this.startAudio()
      }

      if (!v && this.connection) {
        this.stopAudio()
      }
    },

    "item.status": function (a, b) {
      if (a == 11 && b == 10) {
        this.playSound("/start.mp3")
      }
    },
  },
}
