
import { mapActions } from "vuex"
import InfoIcon from "vue-material-design-icons/Information.vue"
import ErrorIcon from "vue-material-design-icons/AlertCircleOutline.vue"
import CloseIcon from "vue-material-design-icons/Close.vue"
import {
  validateNegotiationOfferFromSeller,
  validateNegotiationOfferFromBuyer,
} from "~/utils/negotiation_offer"

export default {
  components: {
    InfoIcon,
    ErrorIcon,
    CloseIcon,
  },

  data() {
    return {
      price: 0,
      server_errors: [],
      errors: [],
      attempted: false,
    }
  },

  computed: {
    id() {
      return this.$store.state.negotiation.modal_id
    },

    n() {
      return (this.item?.negotiations || [])[0]
    },

    loading() {
      return this.$store.state.negotiation.busy[this.id]
    },

    item() {
      return this.$store.state.item.all[this.id]
    },

    is_seller() {
      return this.item?.user_is_seller
    },

    is_buyer() {
      return this.item?.highest_bid_flag && this.n?.type == "seller_offer"
    },

    current_offer() {
      return this.n?.price
    },

    current_price() {
      return this.item?.current_price || 0
    },

    title() {
      const name = this.is_buyer ? "counter" : "offer"
      return this.$t(`negotiation.modal.${name}_title`)
    },

    input_label() {
      const name = this.is_buyer ? "counter" : "offer"
      return this.$t(`negotiation.modal.${name}_input`)
    },

    info() {
      if (this.is_seller) return this.$t("negotiation.modal.info_seller")
      if (this.is_buyer) return this.$t("negotiation.modal.info_buyer")
      return null
    },

    error_count() {
      return [...this.errors, ...this.server_errors].length
    },

    bid_config() {
      const event_id = this.item?.event_id
      return this.$store.state.item.bid_configs[event_id]
    },

    instructions() {
      const config = this.bid_config?.negotiations
      const currency = this.item?.currency
      if (!config || !currency) return ""
      return this.$t("negotiation.modal.instruction_custom", {
        tier2_from: this.$formatCustomPrice(config[1].start, currency, this.useVariant),
        tier1_inc: this.$formatCustomPrice(config[0].multiple_of[0], currency, this.useVariant),
        tier2_inc: this.$formatCustomPrice(config[1].multiple_of[0], currency, this.useVariant),
      })
    },
    useVariant() {
      return this.$i18n.locale == "ja"
    },
  },

  watch: {
    price() {
      this.server_errors = []

      if (this.attempted) {
        this.errors = this.validate()
      }
    },
  },

  methods: {
    ...mapActions("negotiation", ["sellerOffer", "buyerCounter", "closeModal"]),

    async send() {
      if (!this.attempted) {
        this.errors = this.validate()
        this.attempted = true
      }
      if (this.errors.length) {
        this.focus()
        return
      }

      const payload = { item_id: this.id, price: this.price }
      let res
      if (this.is_buyer) {
        res = await this.buyerCounter(payload)
      } else if (this.is_seller) {
        res = await this.sellerOffer(payload)
      }
      if (res.err?.response?.data) {
        this.server_errors.push(res.err.response.data.error)
        this.focus()
      }
    },

    close() {
      this.$emit("close")
      this.closeModal()
    },

    focus() {
      this.$refs.input?.$el?.focus()
    },

    validate() {
      if (this.is_seller) {
        return validateNegotiationOfferFromSeller({
          offer: this.price,
          current_price: this.current_price,
          minimum_price: this.item.minimum_price,
          inc: this.$bidRules.negotiationMul(this.price, this.bid_config),
        })
      } else {
        return validateNegotiationOfferFromBuyer({
          offer: this.price,
          current_price: this.current_price,
          seller_offer: this.current_offer,
          inc: this.$bidRules.negotiationMul(this.price, this.bid_config),
        })
      }
    },
  },

  mounted() {
    document.body.classList.add("modal-open")
  },

  beforeDestroy() {
    document.body.classList.remove("modal-open")
  },
}
