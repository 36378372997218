export default (context, inject) => {
  const theme = context.$config.theme

  const bids = {
    all: (config) => config?.offline[0].step,

    enabled: (price, config) => {
      let rules = config?.offline

      if (!config) return null

      return rules.find((rule) => {
        return price >= rule.start && (price <= rule.end || rule.end === null)
      })?.step
    },

    auto: (current_price, offer, config) => {
      let rules = config?.auto

      if (!config) return null

      let price_rule = rules.find((r) => {
        return current_price >= r.start && (current_price <= r.end || r.end === null)
      })

      if (!price_rule) {
        throw "No auto-bid price rule found!"
      }

      let offer_rule = rules.find((r) => {
        return offer >= r.start && (offer <= r.end || r.end === null)
      })

      if (!offer_rule) {
        throw "No auto-bid offer rule found!"
      }

      return { min: price_rule.auto_inc, mul: offer_rule.multiple_of[0] }
    },

    liveStep: (price, config) => {
      let rules = config?.live

      if (!config) return null

      return rules.find((rule) => {
        return price >= rule.start && (price <= rule.end || rule.end === null)
      })?.step[0]
    },

    negotiationMul: (price, config) => {
      let rules = config?.negotiations

      if (!config) return null

      let rule = rules.find((r) => {
        return price >= r.start && (price <= r.end || r.end === null)
      })

      if (!rule) {
        throw "No negotiation rule found!"
      }

      return rule.multiple_of[0]
    },
  }

  inject("bidRules", bids)
}
