import { render, staticRenderFns } from "./mkk-header-items.vue?vue&type=template&id=3a4993f6"
import script from "./mkk-header-items.vue?vue&type=script&lang=js"
export * from "./mkk-header-items.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MkkDropMenu: require('/opt/atlassian/pipelines/agent/build/components/mkk-drop-menu.vue').default})
