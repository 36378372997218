export default ({ $config }, inject) => {
  const categories = [
    {key: "wrong_information", value: 1},
    {key: "wrong_image", value: 2},
    {key: "defective", value: 3},
    {key: "gray", value: 4},
    {key: "other", value: 0},
  ]

  const kinds = [
    { key: "discount", value: 0 },
    { key: "return", value: 1 },
    { key: "discount_or_return", value: 4 },
    { key: "extension", value: 2 },
    { key: "extension_cancel", value: 3, only_reapply: true },
  ]

  const settlements = [
    { key: "offset", value: 0 },
    { key: "manual_payment", value: 1 },
  ]

  const statuses = {
    applied: 1,
    accepted: 2,
    rejected: 3,
    approved: 4,
    disapproved: 5,
    expired: 6,
    discarded: 0,
  }

  const can_reapply = (item) => {
    const neg = item.post_negotiation
    if(!neg) return false
    if(neg.kind == 2 && neg.status == 2) return true
    return false
  }

  inject("post_negotiations", { categories, kinds, statuses, settlements, can_reapply })
}
