// Called before page load and before each route navigation
export default function ({ store, route, $config, redirect }) {
  // Check UI version
  store.dispatch("versionMatch")

  // Set current UI theme
  if ($config.theme) store.commit("SET_THEME", $config.theme)

  // Clear alerts
  store.dispatch("alerts/clear", { soft: true })

  // Lock live bidding
  store.commit("BIDDING_LOCKED", true)

  // perform redirects here..

  const home = store.state.home_path

  if (navigator.userAgent.indexOf("MSIE") !== -1 || navigator.appVersion.indexOf("Trident/") > -1) {
    // Microsoft Internet Explorer detected (⊙△⊙")
    return redirect("/unsupported_ie")
  } else if (route.path == "/unsupported_ie") {
    return redirect(home)
  }

  // maintenance routes
  if ($config.appEnv == "maintenance" && route.path != "/soon") {
    return redirect("/soon")
  }
  if ($config.appEnv.includes("production") && route.path == "/soon") {
    return redirect(home)
  }

  // redirect to previously stored path
  let rPath = store.state.redirectPath
  if (rPath) {
    if (rPath == route.path) {
      // already here
      store.commit("REDIRECT_PATH", null)
    } else {
      const ignoreRedirect = ["/login", "/loading", "/reset-password"]
      if (!ignoreRedirect.find((p) => route.path.includes(p))) {
        store.commit("REDIRECT_PATH", null)
        return redirect(rPath)
      }
    }
  }

  // redirect to home, if home is not index
  if (route.path == "/" && "/" != home) {
    return redirect(home)
  }
}
