import cloneDeep from "lodash/cloneDeep"

export default (context, inject) => {
  const theme = context.$config.theme
  const theme_suffix = theme == "nj" ? "_nj" : ""

  const boxCode = (item) => {
    if (!item) return "...-.."
    const id = item.box_id
    const num = item.box_no || item.box_number
    return `${id || "..."}-${num ? (num > 9 ? num : "0" + num) : ".."}`
  }


  const MAX_ITEM_IMAGES = 15

  const itemStatus = {
    INACTIVE: 0,
    ACTIVE: 1,
    NEGOTIATING: 2,
    SOLD: 3,
    EXPIRED: 4,
    WON: 5,
    HELD: 6,
    PULLED: 7,
    WON_AFTER_HELD: 8,
    PULLED_AFTER_HELD: 9,
    STANDBY_LIVE: 10,
    ON_LIVE: 11,
  }

  const itemType = {
    BRAND_GOODS: 0,
    JEWELRY: 1,
    LOOSE_GEMS: 2,
    EXPERIENCE: 3,
  }

  const eventStatus = {
    INACTIVE: 0,
    ACTIVE: 1,
    EXPIRED: 2,
    SCHEDULED: 3,
    ON_LIVE: 4,
  }

  const grades = ["N", "S", "SA", "A", "AB", "B+", "B", "B-", "BC", "C", "D"]
  const jewelry_color = ["D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "VLY", "LY"]
  const jewelry_clarity = [
    "FL",
    "IF",
    "VVS-1",
    "VVS-2",
    "VS-1",
    "VS-2",
    "SI-1",
    "SI-2",
    "I-1",
    "I-2",
    "I-3",
  ]
  const jewelry_cutting = ["EXCELLENT", "VERY GOOD", "GOOD", "FAIR", "POOR"]

  const filters = {
    defaults: {
      keywords: "",
      category_id: null,
      subcategory_id: null,
      brand_id: null,
      min_price: null,
      max_price: null,
      min_box_id: null,
      max_box_id: null,
      grades: [],
      jewelry_carat_min: null,
      jewelry_carat_max: null,
      jewelry_clarity: [],
      jewelry_color: [],
      jewelry_cutting: [],
      jewelry_3ex: false,
      jewelry_h_c: false,
    },
    prices: [1000, 5000, 10000, 20000, 50000, 100000, 200000, 500000, 1000000, 5000000],
  }

  const filterObj = (obj, predicate) => Object.fromEntries(Object.entries(obj).filter(predicate))

  const paramsToFilters = (params) => {
    let f = cloneDeep(filterObj(params, ([k, v]) => k in filters.defaults))
    const grades = f.grades?.split(",") || []
    const jewelry_color = f.jewelry_color?.split(",") || []
    const jewelry_clarity = f.jewelry_clarity?.split(",") || []
    const jewelry_cutting = f.jewelry_cutting?.split(",") || []
    const keywords = f.keywords?.trim().substring(0, 60) || ""
    const jewelry_3ex = f.jewelry_3ex == "true" || f.jewelry_3ex === true
    const jewelry_h_c = f.jewelry_h_c == "true" || f.jewelry_h_c === true
    Object.keys(f).forEach((k) => (f[k] = Number(f[k])))
    return {
      ...f,
      keywords,
      grades,
      jewelry_color,
      jewelry_clarity,
      jewelry_cutting,
      jewelry_3ex,
      jewelry_h_c,
    }
  }

  const itemSort = {
    START_PRICE_DESC: 0,
    START_PRICE_ASC: 1,
    NUM_LIKES_DESC: 2,
    CURRENT_PRICE_DESC: 3,
    CURRENT_PRICE_ASC: 4,
    END_DATETIME_DESC: 5,
    END_DATETIME_ASC: 6,
    BOX_ID_NUMBER_DESC: 7,
    BOX_ID_NUMBER_ASC: 8,
    CREATED_DATETIME_DESC: 9,
    CREATED_DATETIME_ASC: 10,
    NUM_BIDS_DESC: 11,
    NUM_BIDS_ASC: 12,
    LIVE_ORDER_DESC: 13,
    LIVE_ORDER_ASC: 14,
    MEMO_UPDATE_ASC: "memo_asc",
    MEMO_UPDATE_DESC: "memo_desc",
    NUM_VIEWS_DESC: 16,
  }

  const sortDefaults = [
    { label: "sort.price_high", value: itemSort.CURRENT_PRICE_DESC },
    { label: "sort.price_low", value: itemSort.CURRENT_PRICE_ASC },

    { label: "sort.start_price_high", value: itemSort.START_PRICE_DESC },
    { label: "sort.start_price_low", value: itemSort.START_PRICE_ASC },

    { label: "sort.ending_soon", value: itemSort.END_DATETIME_ASC },
    { label: "sort.ending_late", value: itemSort.END_DATETIME_DESC },

    { label: "sort.box_id_asc", value: itemSort.BOX_ID_NUMBER_ASC },
    { label: "sort.box_id_desc", value: itemSort.BOX_ID_NUMBER_DESC },

    { label: "sort.likes_count", value: itemSort.NUM_LIKES_DESC },
  ]

  const sortOptions = {
    created: [
      { label: "sort.newest", value: itemSort.CREATED_DATETIME_DESC },
      { label: "sort.oldest", value: itemSort.CREATED_DATETIME_ASC },
    ],
    bids: [
      { label: "sort.bids_desc", value: itemSort.NUM_BIDS_DESC },
      { label: "sort.bids_asc", value: itemSort.NUM_BIDS_ASC },
    ],
    live: [
      { label: "sort.live_order_asc", value: itemSort.LIVE_ORDER_ASC },
      { label: "sort.live_order_desc", value: itemSort.LIVE_ORDER_DESC },
    ],
    memo: [
      { label: "sort.memo_first", value: itemSort.MEMO_UPDATE_ASC },
      { label: "sort.memo_last", value: itemSort.MEMO_UPDATE_DESC },
    ],
    views: [{ label: "sort.view_count", value: itemSort.NUM_VIEWS_DESC }],
  }

  const pageTitle = (t) => {
    var title = `${context.$config.title} ${t}`
    return {
      title,
      meta: [
        {
          hid: "og:title",
          property: "og:title",
          content: title,
        },
      ],
    }
  }

  const auctioneerName = (event, default_value = null) => {
    if (!event || event.loading) return default_value
    return event.company_name || event.eventer_name
  }

  const auctioneerLogo = (event, default_value = "") => {
    if (!event || event.loading) return default_value
    return event.company_image_thumb || event.eventer_logo
  }

  const affiliates = ["monobank", "quark", "nj", "mb"]

  const eventerFromQuery = () => {
    let q = context.route.query
    return affiliates.find((a) => a in q) || null
  }

  const eventerFromUser = () => {
    // theme overrides user data
    if (theme != "default") return theme

    let u = context.store.state?.user
    if (!u) return null

    let keys = affiliates.filter((a) => u.eventer_codes?.includes(a))
    return keys.length ? keys[0] : null
  }

  const masterDataLocale = () => {
    const locale = context.app.i18n.locale
    return context.app.i18n.localeCodes.find((e) => e == locale) || "en"
  }

  const isObject = (v) => {
    // like lodash 'isObject' but arrays also return false
    return typeof v === "object" && !Array.isArray(v) && v !== null
  }

  const itemBrand = (item) => {
    if (item.brand) {
      if (isObject(item.brand)) return item.brand.name
      else return item.brand
    }
  }

  const itemCategory = (item) => {
    if (item.category) {
      if (isObject(item.category)) return item.category.name[masterDataLocale()]
      else return item.category
    }
  }

  const itemSubcategory = (item) => {
    if (item.subcategory) {
      if (isObject(item.subcategory)) return item.subcategory.name[masterDataLocale()]
      else return item.subcategory
    }
  }

  const itemTitle = (item) => {
    let title
    switch (item?.item_type) {
      case 1:
      case 2:
      case 3:
        // LOOSE_GEMS, JEWELRY, EXPERIENCE
        title = itemCategory(item)
        break

      default:
        // legacy "loose" items
        if (item?.category_id == 18) {
          title = itemCategory(item)
        }
        title = itemBrand(item)
    }

    return title || "---"
  }

  const itemDescription = (item) => {
    const d = item
    if (!d) return "---"

    if (theme == "nj") {
      let t = itemType
      if ([t.LOOSE_GEMS, t.JEWELRY].includes(d.item_type)) return d.misc
      return [d.model_number, d.misc].join(" ")
    }

    if (d.item_type == itemType.LOOSE_GEMS) {
      return [
        d.jewelry_carat,
        d.jewelry_color,
        d.jewelry_clarity,
        d.jewelry_cutting,
        d.jewelry_fluorescence,
        d.jewelry_comment,
        d.misc,
      ]
        .filter(Boolean)
        .join(" ")
    }

    return [d.model_number, d.model1, d.model2, d.misc].filter(Boolean).join(" ")
  }

  const userAccountVerified = () => {
    let u = context.store.state?.user
    if (!u) return null
    if (u.source === 1 && u.email_verified && u.approved) {
      return true
    }
    if (u.source !== 1) return true
    return false
  }

  const userSounds = () => {
    return context.store.state?.user?.sounds
  }

  const userBidConfirm = () => {
    if (!context.$feature("bid_confirmation")) return false
    return context.store.state?.user?.bid_confirm
  }

  const themeItem = theme == "nj" ? "nj-item" : "mkk-item"

  const scrollToElement = (elementId, behavior = "smooth") => {
    let el = document.getElementById(elementId)
    if (!el) return
    window.scrollTo({
      top: el.offsetTop - 10,
      behavior: behavior,
    })
  }

  const ordinalNumber = (i) => {
    let j = i % 10,
      k = i % 100
    if (j === 1 && k !== 11) {
      return i + "st"
    }
    if (j === 2 && k !== 12) {
      return i + "nd"
    }
    if (j === 3 && k !== 13) {
      return i + "rd"
    }
    return i + "th"
  }

  const unquotedJSON = (obj) => {
    if (!isObject(obj)) return obj?.toString()
    console.warn(JSON.stringify(obj))
    return JSON.stringify(obj).replaceAll('"', '')
  }

  const SELLER_UPLOADS_METADATA = {
    category_id: { type: [0, 1, 2], component: "mkk-select-category" },
    subcategory_id: { type: [0, 1, 2], component: "mkk-select-subcategory" },
    brand_id: { type: [0, 1, 2], component: "mkk-select-brand" },
    quantity: { type: [0, 1, 2], number: 1 },
    grade: { type: [0], component: "mkk-select-grade" },
    model_number: [0],
    line: [0],
    model1: [0],
    material: [0, 1],
    color: [0],
    serial_number: [0],
    size: [0],
    bracelet: [0],
    rewind: [0],
    price: { type: [0], currency: true },
    weight: [1],
    jewelry_carat: { type: [2], number: 0 },
    jewelry_color: { type: [2], component: "mkk-select-gem" },
    jewelry_clarity: { type: [2], component: "mkk-select-gem" },
    jewelry_cutting: { type: [2], component: "mkk-select-gem" },
    jewelry_sort_no: [2],
    jewelry_3ex: [2],
    jewelry_h_c: [2],
    jewelry_fluorescence: [2],
    jewelry_size: [2],
    jewelry_polish: [2],
    jewelry_symmetry: [2],
    jewelry_shape: [2],
    jewelry_comment: [2],
    jewelry_color_origin: [2],
    jewelry_company_name: [2],
    minimum_price: { type: [0, 1, 2], currency: true },
    accessory_comment: { type: [0, 1, 2], class: "min-w-full" },
    misc: { type: [0, 1, 2], class: "min-w-full" },
    comment: { type: [0, 1, 2], class: "min-w-full" },
    seller_note: { type: [0, 1, 2], class: "min-w-full" },
  }

  inject("boxCode", boxCode)
  inject("itemStatus", itemStatus)
  inject("itemType", itemType)
  inject("eventStatus", eventStatus)
  inject("itemSort", itemSort)
  inject("pageTitle", pageTitle)
  inject("auctioneerName", auctioneerName)
  inject("auctioneerLogo", auctioneerLogo)
  inject("sortDefaults", sortDefaults)
  inject("sortOptions", sortOptions)
  inject("filters", { ...filters, fromParams: paramsToFilters })
  inject("filterObject", filterObj)
  inject("grades", grades)
  inject("gem", { jewelry_color, jewelry_clarity, jewelry_cutting })
  inject("queryAffiliate", eventerFromQuery)
  inject("userAffiliate", eventerFromUser)
  inject("affiliates", affiliates)
  inject("theme", theme)
  inject("theme_suffix", theme_suffix)
  inject("item", {
    title: itemTitle,
    description: itemDescription,
    category: itemCategory,
    subcategory: itemSubcategory,
    brand: itemBrand,
  })
  inject("userAccountVerified", userAccountVerified)
  inject("userSounds", userSounds)
  inject("userBidConfirm", userBidConfirm)
  inject("isObject", isObject)
  inject("themeItem", themeItem)
  inject("scrollTo", scrollToElement)
  inject("ordinalNumber", ordinalNumber)
  inject("uploadsMetadata", SELLER_UPLOADS_METADATA)
  inject("maxItemImages", MAX_ITEM_IMAGES)
  inject("unquotedJSONString", unquotedJSON)
}
