import debounce from "lodash/debounce"
import throttle from "lodash/throttle"
import isEqual from "lodash/isEqual"
import range from "lodash/range"
import sortBy from "lodash/sortBy"
import cloneDeep from "lodash/cloneDeep"
import groupBy from "lodash/groupBy"
import uniqBy from "lodash/uniqBy"
import uniq from "lodash/uniq"
import isEmpty from "lodash/isEmpty"
import mapValues from "lodash/mapValues"
import pickBy from "lodash/pickBy"

export default (context, inject) => {
  inject("lodash", {
    debounce,
    throttle,
    isEqual,
    range,
    sortBy,
    cloneDeep,
    groupBy,
    uniqBy,
    uniq,
    isEmpty,
    mapValues,
    pickBy,
  })
}
