
import GavelIcon from "vue-material-design-icons/Gavel.vue"
import StoreIcon from "vue-material-design-icons/Store.vue"
import BellIcon from "vue-material-design-icons/Bell.vue"
import HeartIcon from "vue-material-design-icons/Heart.vue"
import AccountIcon from "vue-material-design-icons/Account.vue"
import ChartLineVariant from "vue-material-design-icons/ChartLineVariant.vue"
import CogIcon from "vue-material-design-icons/Cog.vue"
import CashIcon from "~/assets/images/icons/cash_stack_gray.svg"
import ShakeIcon from "~/assets/images/icons/handshake_gray.svg"
import CashIconGold from "~/assets/images/icons/cash_stack_primary.svg"
import ShakeIconGold from "~/assets/images/icons/handshake_primary.svg"
import MemoIcon from "vue-material-design-icons/NoteEdit.vue"

export default {
  components: {
    GavelIcon,
    StoreIcon,
    BellIcon,
    HeartIcon,
    AccountIcon,
    CogIcon,
    ChartLineVariant,
    MemoIcon,
  },

  props: ["open"],

  computed: {
    unread_notification_count() {
      return this.$store.state.unread_notification_count || 0
    },

    unread_news_count() {
      return this.$store.state.unread_news_count || 0
    },

    unread() {
      return this.unread_notification_count + this.unread_news_count
    },

    navItems() {
      return [
        {
          icon: "GavelIcon",
          title: this.$t("app.auction"),
          id: "live-link",
          to: "/auction",
        },
        // {
        //   icon: "StoreIcon",
        //   title: "展示会",
        //   id: "auction-link",
        //   to: "/shop",
        // },
        {
          icon: "BellIcon",
          title: this.$t("news.title"),
          id: "news-link",
          to: this.unread_notification_count ? "/news/notifications" : "/news",
        },
        {
          icon: "HeartIcon",
          title: this.$t("profile.likes" + this.$theme_suffix),
          id: "likes-link",
          to: "/likes",
        },
        {
          icon: "MemoIcon",
          title: this.$t("memos.nav"),
          id: "memos-link",
          to: "/memos",
        },
        {
          image: CashIcon,
          activeImage: CashIconGold,
          title: this.$t("profile.bids"),
          id: "bids-link",
          to: "/bids",
        },
        {
          image: ShakeIcon,
          activeImage: ShakeIconGold,
          title: this.$t("profile.purchases"),
          id: "wins-link",
          to: "/wins",
        },
        {
          icon: "ChartLineVariant",
          title: this.$t("past.title"),
          id: "past-link",
          to: "/past-market-price",
        },
        {
          icon: "AccountIcon",
          title: this.$t("app.crumbs.mypage"),
          id: "profile-link",
          to: "/mypage",
        },
        // {
        //   icon: "CogIcon",
        //   title: "設定",
        //   id: "settings-link",
        //   to: "/settings",
        // },
      ].filter((e) => {
        if (this.$feature(e.id.split("-")[0])) {
          return true
        } else {
          return false
        }
      })
    },
  },

  methods: {
    activePath(path) {
      return this.$route.path.split("/").includes(path.split("/")[1])
    },

    linkImg(nav) {
      return this.activePath(nav.to) ? nav.activeImage : nav.image
    },
  },
}
