
export default {
  props: {
    item: { type: Object, required: true },
    format: { String, default: "current_price" },
    price_custom: Number,
  },

  computed: {
    formatted_price() {
      if (Number.isFinite(this.price_custom)) {
        let currency = this.$currencySymbol({ item: this.item })
        let price = this.$formatPrice(this.price_custom, this.item.currency)
        return currency + price
      }

      return this.$formatItemPrice(this.item, this.format)
    },
  },
}
