
export default {
  props: {
    // See below for other themes
    theme: {
      type: String,
      default: "primary",
    },
    // width 100% of parent
    expand: {
      type: Boolean,
      default: false,
    },
    // 30px high
    slim: {
      type: Boolean,
      default: false,
    },
    square: {
      type: Boolean,
      default: false,
    },
    // Custom dimensions
    size: {
      type: [Number, Object],
      default: null,
    },
    // show loading spinner
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    width_class: {
      type: String,
      default: "w-40",
    },
    height_class: {
      type: String,
      default: "h-10",
    },
  },

  computed: {
    loadingColor: function () {
      let col = "light"
      switch (this.theme) {
        case "secondary-light":
          col = "primary"
          break
        case "plain":
        case "transparent":
          col = "dark"
      }
      return col
    },

    sizeStyle: function () {
      if (this.size == null) return

      switch (typeof this.size) {
        case "object":
          return {
            width: this.size.w + "px",
            height: this.size.h + "px",
            minWidth: this.size.w + "px",
            minHeight: this.size.h + "px",
          }
        case "number":
          return {
            width: this.size + "px",
            height: this.size + "px",
            minWidth: this.size + "px",
            minHeight: this.size + "px",
          }
      }
    },
  },

  methods: {
    click() {
      // Because buttons stop events
      if (this.$store.state.live.autoplay_blocked) {
        this.$store.dispatch("live/interact")
      }
      this.$emit("click")
    },
  },
}
