const middleware = {}

middleware['authenticated'] = require('../middleware/authenticated.js')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['session'] = require('../middleware/session.js')
middleware['session'] = middleware['session'].default || middleware['session']

middleware['source'] = require('../middleware/source.js')
middleware['source'] = middleware['source'].default || middleware['source']

middleware['validated'] = require('../middleware/validated.js')
middleware['validated'] = middleware['validated'].default || middleware['validated']

export default middleware
