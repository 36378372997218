
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    offer: {
      type: Number,
      default: 0,
    },
    focus: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
    right: Boolean,
  },

  data() {
    return {
      mutOffer: this.offer,
    }
  },

  computed: {
    currency() {
      const str = this.$currency({ item: this.item })
      return str == "@" ? { prefix: str } : str
    },
    calculatedOffer() {
      return this.$systemToCurrencyPrice(this.mutOffer, this.item.currency)
    },
  },

  methods: {
    setMutOffer(v) {
      this.mutOffer = this.$currencyToSystemPrice(v, this.item.currency)
      this.$emit("change", this.mutOffer)
    },
  },

  mounted() {
    if (this.focus) {
      setTimeout(() => {
        document.getElementById(`currency-input-${this._uid}`).focus()
      }, 100)
    }
  },
}
