import { render, staticRenderFns } from "./mkk-negotiation-modal.vue?vue&type=template&id=54d3af12"
import script from "./mkk-negotiation-modal.vue?vue&type=script&lang=js"
export * from "./mkk-negotiation-modal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MkkPriceInput: require('/opt/atlassian/pipelines/agent/build/components/inputs/mkk-price-input.vue').default,MkkPrice: require('/opt/atlassian/pipelines/agent/build/components/mkk-price.vue').default,MkkButton: require('/opt/atlassian/pipelines/agent/build/components/inputs/mkk-button.vue').default,FullscreenOverlay: require('/opt/atlassian/pipelines/agent/build/components/fullscreen-overlay.vue').default})
