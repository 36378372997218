export default ({ $config }, inject) => {
  const show = (item) => {
    if (!item) return false

    const held = item.status == 6
    const is_seller = item.user_is_seller
    const is_buyer = item.highest_bid_flag

    return held && (is_seller || is_buyer)
  }

  inject("negotiations", { show })
}
