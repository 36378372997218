
export default {
  data() {
    return {
      online: window.navigator.onLine,
    }
  },

  methods: {
    setState() {
      this.online = window.navigator.onLine
    },
  },

  created() {
    window.addEventListener("online", this.setState)
    window.addEventListener("offline", this.setState)
  },

  beforeDestroy() {
    window.removeEventListener("online", this.setState)
    window.removeEventListener("offline", this.setState)
  },
}
