import moment from "moment"

const itemStatus = {
  INACTIVE: 0,
  ACTIVE: 1,
  NEGOTIATING: 2,
  SOLD: 3,
  EXPIRED: 4,
  WON: 5,
  HELD: 6,
  PULLED: 7,
  WON_AFTER_HELD: 8,
  PULLED_AFTER_HELD: 9,
  STANDBY_LIVE: 10,
  ON_LIVE: 11,
}

const itemKind = {
  EXHIBITION: 0,
  OFFLINE_AUCTION: 1,
  LIVE_AUCTION: 2,
}

const eventStatus = {
  INACTIVE: 0,
  ACTIVE: 1,
  EXPIRED: 2,
  SCHEDULED: 3,
  ON_LIVE: 4,
}

const past = (date) => {
  return moment(date).isBefore(moment())
}

const err = (data, message) => {
  return {
    error: true,
    data,
    message,
  }
}

const ok = (data) => {
  return {
    ok: true,
    data: data,
    error: false,
  }
}

const offlineItemBiddable = (status) => {
  const is = [itemStatus.ACTIVE, itemStatus.STANDBY_LIVE]
  return is.includes(status)
}

const liveHybridItemBiddable = (status, live_event_status) => {
  switch (status) {
    case itemStatus.ON_LIVE:
      return live_event_status == eventStatus.ON_LIVE

    case itemStatus.ACTIVE:
      return true

    case itemStatus.STANDBY_LIVE:
      return [eventStatus.ACTIVE, eventStatus.INACTIVE].includes(live_event_status)
  }
}

const liveOnlyItemBiddable = (status, live_event_status) => {
  switch (status) {
    case itemStatus.ON_LIVE:
      return live_event_status == eventStatus.ON_LIVE

    case itemStatus.ACTIVE:
      return true

    case itemStatus.STANDBY_LIVE:
      return eventStatus.ACTIVE == live_event_status
  }
}

const offlineItemAutoBiddable = (status) => {
  const vs = [itemStatus.ACTIVE, itemStatus.STANDBY_LIVE]
  return vs.includes(status)
}

const liveItemAutoBiddable = (status) => {
  const vs = [itemStatus.ACTIVE, itemStatus.STANDBY_LIVE]
  return vs.includes(status)
}

export const checkBiddable = (live_event, event, item) => {
  const k = item?.kind
  const s = item?.status || item?.item_status
  const d = item?.end_datetime
  const les = live_event?.status
  const es = event?.status
  const exclude = item?.excluded_bidder === true

  const data = [`kind:${k}`, `status:${s}`, `live_event_status:${les}`, `event_status:${es}`]

  if (item?.user_is_seller) {
    return err(data, "user_is_seller")
  }

  if (d && past(d)) {
    return err(data, "item_expired")
  }

  if (es == eventStatus.EXPIRED) {
    return err(data, "event_expired")
  }

  if (es == eventStatus.INACTIVE) {
    return err(data, "event_inactive")
  }

  if (exclude) {
    return err(data, "excluded_bidder")
  }

  if (live_event?.bid_type == 1 && les != eventStatus.ON_LIVE) {
    return err(data, "live_bid_only_live_event_not_started")
  }

  let item_type = "offline"

  if (item?.event_id == item?.live_event_id) {
    item_type = "live_only"
  }

  if (item?.event_id != item?.live_event_id) {
    item_type = "live_hybrid"
  }

  switch (item_type) {
    case "offline":
      const res1 = offlineItemBiddable(s)
      if (res1) return ok(data)
      break

    case "live_hybrid":
      const res2 = liveHybridItemBiddable(s, les)
      if (res2) return ok(data)
      break

    case "live_only":
      const res3 = liveOnlyItemBiddable(s, les)
      if (res3) return ok(data)
      break
  }

  return err(data)
}

export const checkAutoBiddable = (live_event, event, item) => {
  const k = item?.kind
  const s = item?.status || item?.item_status
  const d = item?.end_datetime
  const les = live_event?.status
  const es = event?.status
  const exclude = item?.excluded_bidder === true

  const data = [`kind:${k}`, `status:${s}`, `live_event_status:${les}`, `event_status:${es}`]

  if (item?.user_is_seller) {
    return err(data, "user_is_seller")
  }

  if (d && past(d)) {
    return err(data, "item_expired")
  }

  if (es == eventStatus.EXPIRED) {
    return err(data, "event_expired")
  }

  if (es == eventStatus.INACTIVE) {
    return err(data, "event_inactive")
  }

  if (exclude) {
    return err(data, "excluded_bidder")
  }

  if (live_event?.bid_type == 1) {
    return err(data, "live_bid_only")
  }

  switch (k) {
    case itemKind.OFFLINE_AUCTION:
      const res1 = offlineItemAutoBiddable(s)
      if (res1) return ok(data)
      break

    case itemKind.LIVE_AUCTION:
      const res2 = liveItemAutoBiddable(s)
      if (res2) return ok(data)
      break
  }

  return err(data)
}
