
import BellIcon from "vue-material-design-icons/Bell.vue"
import BellOutlineIcon from "vue-material-design-icons/BellOutline.vue"
import LogoutIcon from "vue-material-design-icons/Logout.vue"
import AccountIcon from "vue-material-design-icons/Account.vue"

export default {
  components: {
    BellIcon,
    BellOutlineIcon,
    LogoutIcon,
    AccountIcon,
  },

  data() {
    return {
      logging_out: false,
    }
  },

  computed: {
    userName() {
      return this.$store.state.user?.name
    },

    userInitial() {
      return this.userName?.charAt(0).toUpperCase()
    },

    userAvatar() {
      return this.$store.state?.user?.profile_image
    },

    unread_notification_count() {
      return this.$store.state.unread_notification_count || 0
    },

    unread_news_count() {
      return this.$store.state.unread_news_count || 0
    },

    totalUnread() {
      return this.unread_notification_count + this.unread_news_count
    },

    unreadCount() {
      return this.totalUnread > 9 ? "◍" : this.totalUnread
    },

    newsLink() {
      return this.unread_notification_count ? "/news/notifications" : "/news"
    },
  },

  methods: {
    async logout() {
      if (this.logging_out) return
      this.logging_out = true
      this.$store.commit("auction/CLEAR_EVENT_QUERIES")
      await this.$logout()
    },
  },
}
