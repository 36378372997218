export const state = () => ({
  busy: {},
  modal_id: null,
})

export const mutations = {
  NEGOTIATING_START(state, id) {
    state.busy = { ...state.busy, [id]: true }
  },

  NEGOTIATING_END(state, id) {
    state.busy = { ...state.busy, [id]: false }
    delete state.busy[id]
  },

  OPEN_MODAL(state, id) {
    state.modal_id = id
  },

  CLOSE_MODAL(state) {
    state.modal_id = null
  },
}

export const actions = {
  async openModal({ commit }, { item_id }) {
    commit("OPEN_MODAL", item_id)
  },

  async closeModal({ commit }) {
    commit("CLOSE_MODAL")
  },

  async sellerOffer({ commit }, { item_id, price }) {
    commit("NEGOTIATING_START", item_id)
    const res = await this.$api.post(`negotiations/${item_id}/seller/offer`, {
      price,
    })
    if (!res.err) commit("CLOSE_MODAL")
    commit("NEGOTIATING_END", item_id)
    return res
  },

  async buyerAccept({ commit }, { item_id }) {
    commit("NEGOTIATING_START", item_id)
    const res = await this.$api.post(`negotiations/${item_id}/buyer/accept`)
    commit("NEGOTIATING_END", item_id)
    return res
  },

  async buyerReject({ commit }, { item_id }) {
    commit("NEGOTIATING_START", item_id)
    const res = await this.$api.post(`negotiations/${item_id}/buyer/reject`)
    commit("NEGOTIATING_END", item_id)
    return res
  },

  async buyerCounter({ commit }, { item_id, price }) {
    commit("NEGOTIATING_START", item_id)
    const res = await this.$api.post(`negotiations/${item_id}/buyer/offer`, {
      price,
    })
    if (!res.err) commit("CLOSE_MODAL")
    commit("NEGOTIATING_END", item_id)
    return res
  },

  async sellerAccept({ commit }, { item_id }) {
    commit("NEGOTIATING_START", item_id)
    const res = await this.$api.post(`negotiations/${item_id}/seller/accept`)
    commit("NEGOTIATING_END", item_id)
    return res
  },

  async sellerReject({ commit }, { item_id }) {
    commit("NEGOTIATING_START", item_id)
    const res = await this.$api.post(`negotiations/${item_id}/seller/reject`)
    commit("NEGOTIATING_END", item_id)
    return res
  },

  async sellAtCurrent({ commit }, { item_id }) {
    commit("NEGOTIATING_START", item_id)
    const res = await this.$api.post(`negotiations/${item_id}/seller/sell_current`)
    commit("NEGOTIATING_END", item_id)
    return res
  },

  async noSale({ commit }, { item_id }) {
    commit("NEGOTIATING_START", item_id)
    const res = await this.$api.post(`negotiations/${item_id}/seller/not_selling`)
    commit("NEGOTIATING_END", item_id)
    return res
  },
}
