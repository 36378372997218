
export default {
  props: {
    event_id: {
      type: [Number, String],
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },

  computed: {
    thumbnail() {
      return this.item.thumbnails[0]
    },

    image() {
      return this.item.images[0]
    },

    ended() {
      return this.item && ![10, 11].includes(this.item.status)
    },

    diamondIcon() {
      switch (this.$theme) {
        case "nj":
          return require("~/assets/images/nj/diamond_primary.svg")
        default:
          return require("~/assets/images/icons/diamond_primary.svg")
      }
    },
  },
}
