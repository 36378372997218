
import { mapGetters } from "vuex"

export default {
  data() {
    return {
      affiliate: null,
    }
  },

  created() {
    this.affiliate = this.$theme != "default" ? this.$theme : this.$queryAffiliate()
  },
}
