import Vue from "vue"
import { logEvent } from "firebase/analytics"

export const state = () => ({
  event: {},
  eventMeta: {},
  gemEventMeta: {},
  eventListMeta: {
    active: null,
    ended: null,
  },
  box: {},
  boxListMeta: {},
  revisionLists: {},
  eventQueries: {},
  pageQueries: {},
  activeEventCategories: null,
  endedEventCategories: null,
  is_bidding: null,
})

export const mutations = {
  UPDATE_EVENT(state, data) {
    const e = state.event[data.id]
    Vue.set(state.event, `${data.id}`, e ? { ...e, ...data } : data)
  },
  SET_EVENT_LIST_METADATA(state, { name, data }) {
    Vue.set(state.eventListMeta, `${name}`, {
      ids: data.collection.map((e) => e.id),
      ...data.meta,
    })
  },
  SET_EVENT_METADATA(state, { data, id }) {
    Vue.set(state.eventMeta, `${id}`, data)
  },
  SET_GEM_EVENT_METADATA(state, { data, id }) {
    state.gemEventMeta = { ...state.gemEventMeta, [id]: data }
  },

  SET_BOXES(state, data) {
    for (const box of data) {
      Vue.set(state.box, `${box.box_id}`, box)
    }
  },
  SET_BOX_LIST_META(state, data) {
    state.boxListMeta = {
      ids: data.collection.map((e) => e.box_id),
      ...data.meta,
    }
  },

  SAVE_EVENT_QUERY(state, { query, event }) {
    state.eventQueries[event] = query
  },
  CLEAR_EVENT_QUERIES(state) {
    state.eventQueries = {}
  },
  SAVE_QUERY(state, { path, query }) {
    state.pageQueries = {
      ...state.pageQueries,
      [path]: query,
    }
  },

  SET_REVISION_LIST(state, { event_id, data }) {
    Vue.set(state.revisionLists, event_id, data)
  },
  SET_ACTIVE_EVENT_CATEGORIES(state, data) {
    state.activeEventCategories = data
  },
  SET_ENDED_EVENT_CATEGORIES(state, data) {
    state.endedEventCategories = data
  },

  BIDDING(state, v) {
    state.is_bidding = v
  },
}

export const getters = {
  eventQuery: (state) => (eventId) => {
    return state.eventQueries[eventId]
  },
}

export const actions = {
  async getEvent({ state, commit }, { id }) {
    if (!id) return { err: "Missing param: id" }
    if (state.event[id]) return { data: state.event[id] }
    commit("UPDATE_EVENT", { id, loading: true })
    let res = await this.$api.get(`events/${id}`)
    if (res.data) commit("UPDATE_EVENT", { loading: false, ...res.data })
    return res
  },

  async updateEvent({ commit }, event) {
    commit("UPDATE_EVENT", event)
  },

  async activeEvents({ dispatch }, params) {
    let res = await this.$api.get("events/active_events", params)
    if (res.data) dispatch("setEventData", { name: "active", data: res.data })
    return res
  },

  async endedEvents({ dispatch }, params) {
    let res = await this.$api.get("events/finished_events", params)
    if (res.data) dispatch("setEventData", { name: "ended", data: res.data })
    return res
  },

  async activeEventCategories({ commit }, params) {
    let res = await this.$api.get("events/active_events_categories", params)
    if (res.data) commit("SET_ACTIVE_EVENT_CATEGORIES", res.data)
    return res
  },

  async endedEventCategories({ commit }, params) {
    let res = await this.$api.get("events/finished_events_categories", params)
    if (res.data) commit("SET_ENDED_EVENT_CATEGORIES", res.data)
    return res
  },

  async eventMetadata({ commit }, { id }) {
    let res = await this.$api.get(`events/${id}/metadata_2`, { id })
    if (res.data) commit("SET_EVENT_METADATA", { data: res.data, id })
    return res
  },

  async gemEventMetadata({ commit }, { id }) {
    let res = await this.$api.get(`events/${id}/metadata/jewelry`, { id })
    if (res.data) commit("SET_GEM_EVENT_METADATA", { data: res.data, id })
    return res
  },

  async setEventData({ commit }, { name, data }) {
    if (name) commit("SET_EVENT_LIST_METADATA", { name, data })
    for (const event of data.collection) {
      commit("UPDATE_EVENT", event)
    }
  },

  async boxes({ commit }, { event_id, params }) {
    let res = await this.$api.get(`events/${event_id}/items/boxes`, params)
    if (res.data) {
      commit("SET_BOX_LIST_META", res.data)
      commit("SET_BOXES", res.data.collection)
    }
    return res
  },

  async bid({ commit }, { event_id, item_id, price }) {
    commit("BIDDING", true)
    let data = { price }
    const res = await this.$api.post(`events/${event_id}/items/${item_id}/bid`, data)
    commit("BIDDING", false)
    return res
  },

  async autoBid({ commit, dispatch, rootState }, { event_id, item_id, price }) {
    let data = { price }
    let res = await this.$api.post(`events/${event_id}/items/${item_id}/auto_bid`, data)
    if (res.err) {
      logEvent(this.$fire.analytics, "autobid_error", {
        value: `${event_id}/${item_id}`,
        price: price,
        source: rootState.source,
        status: res.err.response?.status,
        data: JSON.stringify(res.err.response?.data),
      })
    } else {
      logEvent(this.$fire.analytics, "autobid", {
        value: `${event_id}/${item_id}`,
        price: price,
        source: rootState.source,
      })
      let msg = res.status == 201 ? "success.autobid_create" : "success.autobid_mod"
      dispatch(
        "alerts/create",
        { type: "success", message: this.$i18n.t(msg), duration: 5 },
        { root: true }
      )
    }
    return res
  },

  async getRevisions({ commit }, { event_id }) {
    let res = await this.$api.get(`events/${event_id}/revisions`)
    if (res.data) commit("SET_REVISION_LIST", { event_id, data: res.data.collection })
    return res
  },

  async winSeen({}, data) {
    return await this.$api.put("win_seen", data)
  },

  async restoreQuery({ state }, { path, query }) {
    const q = state.pageQueries[path]
    if (!q) return

    const j = JSON.stringify(q)
    if (j == "") return
    if (j == "{}") return
    if (j == JSON.stringify(query)) return
    await this.$router.replace({ query: q })
  },

  async hybridEvents({ dispatch }, { id }) {
    let res = await this.$api.get(`events/${id}/sources`)
    if (res.data) dispatch("setEventData", { data: res.data })
    return res
  },
}
