
export default {
  data() {
    return {
      processing: false,
      terms: null,
    }
  },

  methods: {
    async agree() {
      this.processing = true
      this.$nuxt.$loading.start()
      await this.$store.dispatch("acceptTerms")
      this.processing = false
      this.$nuxt.$loading.finish()
    },
  },

  async created() {
    this.terms = await this.$getTerms()
  },
}
