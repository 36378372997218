import axios from "axios"
import moment from "moment"
import { setUserId } from "firebase/analytics"
import orderBy from "lodash/orderBy"

const USER_RETRIES = 10

let userFetchTimer = null
let versionCheckTimer = null
let userFetching = false

export const mergeItem = (a, b) => {
  if (location.search.match(/debug/) && b._ts) {
    console.log(moment(b._ts).diff(moment()))
  }

  if (b._update_type) {
    return { ...a, ...b }
  } else if (b.reset) {
    a = { ...a }
    delete a.negotiations
    return { ...a, ...b }
  } else if (b.current_price >= a.current_price) {
    // Make sure the price doesn't go down
    return { ...a, ...b }
  } else if (a.start_price != b.start_price) {
    // This is a new edge case where the price
    // could potentially go down if the start_price
    // changes and goes down, it's probably not
    // going to happen, but I need to have this
    // just in case they lowered the start price
    return { ...a, ...b }
  } else {
    return a
  }
}

export const updateItem = (state, key, data) => {
  const items = state[key] || {}
  const item = items[data.id] || { current_price: 0 }
  if (data._update_type && !items[data.id]) return
  return mergeItem(item, data)
}

export const state = () => ({
  ws_state: null,
  source: "web",
  version: "",
  user: null,
  user_tries: USER_RETRIES,
  brands: null,
  news: null,
  notifications: null,
  notification_months: null,
  unread_notification_count: null,
  unread_news_count: null,
  categories: null,
  subcategories: null,
  live_bid_locked: true,
  invoices: null,
  invoices_events: null,
  theme: null,
  server_time_diff: null,
  session_affiliate: null,
  home_path: "auction",
  api_token_local: localStorage.getItem("api_token"),
  api_token_session: sessionStorage.getItem("api_token"),
  locale: "ja",
  locale_switch: null,
  shrink_countdown: false,
  affiliate_links: null,
})

export const getters = {
  authenticated: (state) => {
    return state.user != null
  },

  api_token: (state) => {
    return state.api_token_session || state.api_token_local
  },

  showTerms: (state) => {
    return state.user?.terms_accepted == false
  },

  category: (state) => (item, customData, i18n) => {
    const id = item?.category_id
    const categories = (customData || state.categories)
    if (categories) {
      const entry = categories?.find((c) => c.id == id)
      if (entry) {
        const locale = i18n.locale
        const lang = i18n.localeCodes.find((e) => e == locale) || "ja"
        return (entry.name_json || entry.name)[lang]
      }
    }
    return null
  },

  subcategory: (state) => (item, customData, i18n) => {
    const cid = item?.category_id
    const id = item?.subcategory_id
    const subcategories = (customData || state.subcategories)
    if (subcategories) {
      const entry = subcategories?.find((s) => s.category_id == cid && s.id == id)
      if (entry) {
        const locale = i18n.locale
        const lang = i18n.localeCodes.find((e) => e == locale) || "ja"
        return (entry.name_json || entry.name)[lang]
      }
    }
    return null
  },

  brand_obj: (state) => (item, customData) => {
    return (customData || state.brands)?.find((b) => b.id == item?.brand_id)
  },

  brand: (_, getters) => (item, customData) => {
    return getters.brand_obj(item, customData)?.name
  },
}

export const mutations = {
  WS_STATE(state, v) {
    state.ws_state = v
  },
  SET_SOURCE(state, v) {
    state.source = v
  },
  SET_APP_VERSION(state, v) {
    state.version = v
  },
  REDIRECT_PATH(state, path) {
    state.redirectPath = path
  },

  AGREE_TERMS(state) {
    if (state.user) {
      state.user.terms_accepted = true
    }
  },
  SET_USER(state, data) {
    state.user_tries = USER_RETRIES
    state.user = data
  },
  UPDATE_USER(state, data) {
    state.user = { ...state.user, ...data }
  },
  SET_AVATAR(state, url) {
    if (state.user) state.user.profile_image = url
  },
  SET_BRANDS(state, data) {
    state.brands = data
  },
  SET_CATEGORIES(state, data) {
    state.categories = data
  },
  SET_SUBCATEGORIES(state, data) {
    state.subcategories = data
  },
  SET_NEWS(state, data) {
    state.news = data
  },
  SET_NOTIFICATIONS(state, data) {
    state.notifications = data
  },
  SET_UNREAD_NOTIFICATION_COUNT(state, data) {
    state.unread_notification_count = data
  },
  SET_UNREAD_NEWS_COUNT(state, data) {
    state.unread_news_count = data
  },
  SET_INVOICES(state, data) {
    state.invoices = data
  },
  SET_INVOICE_EVENTS(state, data) {
    state.invoices_events = data
  },
  UPDATE_NOTIFICATION(state, data) {
    state.notifications = {
      ...state.notifications,
      collection: state.notifications.collection.map((e) => {
        if (e.id == data.id) return data
        return e
      }),
    }
  },
  READ_NOTIFICATION(state, id) {
    state.notifications = {
      ...state.notifications,
      collection: state.notifications.collection.map((e) => {
        if (e.id == id) return { ...e, read: true }
        return e
      }),
    }
  },
  SET_NOTIFICATION_MONTHS(state, data) {
    state.notification_months = data
  },
  UPDATE_NEWS(state, data) {
    state.news = {
      ...state.news,
      collection: state.news.collection.map((e) => {
        if (e.id == data.id) return data
        return e
      }),
    }
  },
  READ_NEWS(state, id) {
    state.news = {
      ...state.news,
      collection: state.news.collection.map((e) => {
        if (e.id == id) return { ...e, read: true }
        return e
      }),
    }
  },
  BIDDING_LOCKED(state, v) {
    state.live_bid_locked = v
  },
  // === DEBUG BID BAR VARIANT ===
  SET_BID_BAR_VARIANT(state, v) {
    state.bidBarVariant = v
  },
  // === DEBUG BID BAR VARIANT END ===,
  SET_THEME(state, v) {
    state.theme = v
  },
  SET_SERVER_DIFF(state, diff) {
    state.server_time_diff = diff
  },
  SAVE_SESSION_AFFILIATE(state, a) {
    state.session_affiliate = a
  },
  CLEAR_SESSION_AFFILIATE(state) {
    state.session_affiliate = null
  },
  INSERT_BRAND(state, brand) {
    if (!state.brands) state.brands = [brand]
    else {
      state.brands.push(brand)
      state.brands = orderBy(state.brands, ["initial_en", "name", "id"])
    }
  },
  UPDATE_BRAND(state, brand) {
    if (!state.brands) state.brands = [brand]
    else {
      let index = state.brands.findIndex((e) => e.id == brand.id)
      if (index != -1) state.brands.splice(index, 1, brand)
    }
  },
  SET_API_TOKEN_SESSION(state, token) {
    state.api_token_session = token
  },
  SET_API_TOKEN_LOCAL(state, token) {
    state.api_token_local = token
  },
  CLEAR_API_TOKEN(state) {
    if (state.api_token_session) {
      sessionStorage.removeItem("api_token")
      state.user = null
      state.api_token_session = null
    } else {
      localStorage.removeItem("api_token")
      state.user = null
      state.api_token_local = null
    }
  },
  CLEAR_SESSION_STATE(state) {
    state.user = null
    state.auction.events = null
    state.item.search = null
  },
  USER_FETCH_TIMEOUT(state) {
    --state.user_tries
  },
  SET_LOCALE(state, locale) {
    state.locale = locale
  },
  SET_LOCALE_SWITCH(state, locale) {
    if (!locale) {
      state.locale_switch = null
      return
    }
    let select = this.$i18n.localeCodes.find((e) => e == locale) || "en"
    state.locale_switch = select
  },
  SET_COUNTDOWN_SHRINK(state, v) {
    state.shrink_countdown = v
  },
  SET_AFFILIATE_LINKS(state, data) {
    state.affiliate_links = data
  }
}

export const actions = {
  async versionMatch({ state, commit }) {
    // abort until 10s passed since the last check
    if (versionCheckTimer) return state.version
    else {
      versionCheckTimer = setTimeout(() => (versionCheckTimer = null), 10_000)
    }

    // Timestamp ensures no caching
    let res = await axios.get(`/version.txt?timestamp=${new Date().getTime()}`)
    let version = res.data.replace(/\-/g, ".")

    if (version != state.version) {
      console.info(`New app version: ${version}, (previously: ${state.version}).`)

      commit("SET_APP_VERSION", version)

      // Clean up persisted state here..

      // Reload UI
      window.location.reload()
    }

    return version
  },

  // === DEBUG BID BAR VARIANT ===
  async setBidBarVariant({ commit }, v) {
    commit("SET_BID_BAR_VARIANT", v)
  },
  // === DEBUG BID BAR VARIANT END ===

  async user({ state, commit, dispatch }) {
    if (userFetchTimer) clearTimeout(userFetchTimer)
    if (userFetching) return

    userFetching = true
    let res = await this.$api.get("users/me")
    userFetching = false
    if (res.data) {
      commit("SET_USER", res.data)

      // If there is no affiliate from query, assign user eventer code
      if (state.session_affiliate == null) {
        let af = res.data.eventer_codes?.filter(e => e != "monobank")[0] || null
        commit("SAVE_SESSION_AFFILIATE", af)
      }
        
      if (this.$feature("locale_switcher")) {
        let iso = res.data.locale_iso || "ja-JP"
        let locale = this.$i18n.locales.find((e) => e.iso == iso)?.code

        if (state.locale_switch !== null) {
          commit("SET_LOCALE_SWITCH", null)
          // Login page locale switch was set - ensure user data match.
          if (locale != this.$i18n.locale) {
            await dispatch("updateProfile", { user: { locale: this.$i18n.locale } })
            dispatch("user")
          }
        } else {
          if (locale != this.$i18n.locale) dispatch("setLocale", locale)
        }
      }
      setUserId(this.$fire.analytics, res.data.firebase_uid)
    } else if (res.err) {
      if (res.err.message.match(/network error/i)) {
        userFetchTimer = setTimeout(() => {
          commit("USER_FETCH_TIMEOUT")
          if (state.user_tries > 0) {
            dispatch("user")
          } else {
            this.$sentry.captureMessage("user fetch failed retries - network error!")
          }
        }, 500)
      } else {
        await this.$logout()
        let af = state.session_affiliate
        let query = af ? { [af]: null } : null
        this.$router.push({ path: this.localePath("login"), query })
      }
    }

    return res
  },

  async acceptTerms({ commit }) {
    let res = await this.$api.put("users/me/accept_terms")
    if (!res.err) commit("AGREE_TERMS")
    return res
  },

  async updateProfile({ commit }, data) {
    let res = await this.$api.put("users/me", data)
    if (res.data) commit("SET_USER", res.data)
    return res
  },

  async brands({ commit }) {
    let res = await this.$api.get("brands")
    if (res.data) commit("SET_BRANDS", res.data.collection)
    return res
  },

  async categories({ commit }) {
    let res = await this.$api.get("categories")
    if (res.data) commit("SET_CATEGORIES", res.data.collection)
    return res
  },

  async subcategories({ commit }) {
    let res = await this.$api.get("subcategories")
    if (res.data) commit("SET_SUBCATEGORIES", res.data.collection)
    return res
  },

  async visitItemPage({}, { item_id, event_id }) {
    return await this.$api.post(`events/${event_id}/items/${item_id}/visit`)
  },

  async getNews({ commit }, { page, per_page }) {
    let params = {
      page: page,
      per_page: per_page,
      sort: 0,
    }
    let res = await this.$api.get("news", params)
    if (res.data) commit("SET_NEWS", res.data)
    return res
  },

  async getNotifications({ commit }, params) {
    let res = await this.$api.get("notifications", params)
    if (res.data) commit("SET_NOTIFICATIONS", res.data)
    return res
  },

  async getUnreadNotificationCount({ commit }) {
    let res = await this.$api.get("notifications/unread_count")
    if (res.data) commit("SET_UNREAD_NOTIFICATION_COUNT", res.data.count)
    return res
  },

  async getUnreadNewsCount({ commit }) {
    let res = await this.$api.get("news/unread_count")
    if (res.data) commit("SET_UNREAD_NEWS_COUNT", res.data.count)
    return res
  },

  async readNotification({ dispatch, commit }, id) {
    let res = await this.$api.post(`notifications/${id}/read`)
    if (res.data?.message == "already_read") commit("READ_NOTIFICATION", id)
    else if (res.data) commit("UPDATE_NOTIFICATION", res.data)
    dispatch("getUnreadNotificationCount")
    return res
  },

  async readAllNotifications() {
    return await this.$api.post(`notifications/read_all`)
  },

  async getNotificationMonths({ commit }) {
    let res = await this.$api.get("notifications/months")
    if (res.data) commit("SET_NOTIFICATION_MONTHS", res.data.collection)
  },

  async readNews({ dispatch, commit }, id) {
    let res = await this.$api.post(`news/${id}/read`)
    if (res.data?.message == "already_read") commit("READ_NEWS", id)
    else if (res.data) commit("UPDATE_NEWS", res.data)
    dispatch("getUnreadNewsCount")
    return res
  },

  async getTimeline({}, { event_id, item_id }) {
    return await this.$api.get(`events/${event_id}/items/${item_id}/timeline`)
  },

  async ping({ commit }, data) {
    let start = new Date()
    let res = await this.$api.get("ping", data)
    let now = new Date()
    if (!res.err) {
      if (now - start > 1000) {
        console.error("Unreliable server time. Request took over 1s!")
        return res
      }
      let diff = this.$moment(res.data.now).diff(now)
      commit("SET_SERVER_DIFF", diff)
      this.$updateMomentTime(diff)
    }
    return res
  },

  async signup({}, data) {
    console.warn("!signup") // @todo test refactored request
    var form = new FormData()
    for (const k in data) {
      if (![null, undefined].includes(data[k])) {
        form.append(k, data[k])
      }
    }
    return await this.$api.post_multipart_tokenless("signup", form)
  },

  async verifyEmail({}, token) {
    console.warn("!verifyEmail") // @todo test refactored request
    return await this.$api.post_tokenless("verify_email", { token })
  },

  async resendEmailVerification({ dispatch }, { email }) {
    console.warn("!resendEmailVerification") // @todo test refactored request
    let res = await this.$api.post_tokenless("verify_email/resend", { email })
    if (res.err?.response?.status == 422) {
      dispatch("user")
    }
    return res
  },

  async saveMemo({}, { item_id, memo }) {
    return await this.$api.post("memos", { item_id, memo })
  },

  async deleteMemo({}, { item_id }) {
    return await this.$api.delete(`memos/${item_id}`)
  },

  async uploadAvatar({ commit }, { file, onProgress }) {
    // Get signed URL + data
    const meta = await this.$api.post("uploads/signed/avatar", {
      content_type: file.type,
    })

    if (meta.err) return meta

    const request = axios.create({
      crossdomain: true,
      headers: { "Content-Type": file.type },
    })

    // Upload file to signed URL
    const upload_res = await request.put(meta.data.signed_url, file, {
      onUploadProgress: (e) => {
        onProgress(Math.round((e.loaded * 100) / e.total))
      },
    })

    if (upload_res.err) return upload_res

    // Get modified user data
    const res = await this.$api.post("uploads/uploaded/avatar", {
      signed_data: meta.data.signed_data,
    })

    if (res.err) return res

    commit("SET_AVATAR", res.data?.profile_image)
    return res
  },

  async deleteAvatar({ commit }) {
    const res = this.$api.delete("users/me/delete_profile_image")
    if (res.err) return res
    commit("SET_AVATAR", null)
    return res
  },

  async storageEvent({ state, commit }, e) {
    if (state.api_token_session) return

    if (e.key == "api_token") {
      if (e.newValue) {
        commit("SET_API_TOKEN_LOCAL", e.newValue)
      } else {
        commit("SET_API_TOKEN_LOCAL", null)
        commit("CLEAR_SESSION_STATE", null)

        let af = state.session_affiliate
        let query = af ? { [af]: null } : null
        this.$router.push({ path: this.localePath("login"), query })
      }
    }
  },

  async getInvoices({ commit }, params) {
    let res = await this.$api.get("invoices", params)
    if (res.data) commit("SET_INVOICES", res.data)
    return res
  },

  async getInvoiceEvents({ commit }) {
    let res = await this.$api.get("invoices/events")
    if (res.data) commit("SET_INVOICE_EVENTS", res.data)
    return res
  },

  async setLocale({ dispatch, commit }, locale) {
    if (this.$router.currentRoute?.path.includes("/loading")) {
      setTimeout(() => dispatch("setLocale", locale), 250)
      return
    }
    let select = this.$i18n.localeCodes.find((e) => e == locale) || "en"
    this.$i18n.setLocale(select)
    commit("SET_LOCALE", select)
  },

  async getAffiliateLinks({ commit }) {
    let res = await this.$api.get("eventer_links")
    if (res.data) commit("SET_AFFILIATE_LINKS", res.data)
    return res
  },
}
