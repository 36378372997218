import axios from "axios"

export const state = () => ({
  items: null,
  item: {},
})

export const mutations = {
  SET_ITEMS(state, v) {
    state.items = v
  },
  SET_ITEM(state, v) {
    state.item = {
      ...state.item,
      [v.id]: v,
    }
  },
}

export const actions = {
  async getItems({ commit }, { params }) {
    let res = await this.$api.get("items", params)
    if (res.data) commit("SET_ITEMS", res.data)
    return res
  },

  async getItem({ commit }, { item_id }) {
    let res = await this.$api.get(`items/${item_id}`)
    if (res.data) commit("SET_ITEM", res.data)
    return res
  },

  async getPriceHistory({}, { params }) {
    return await this.$api.get("price_history", params)
  },
}
