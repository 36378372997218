
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    mini: {
      type: Boolean,
    },
  },

  computed: {
    show() {
      // is live and finished item
      return this.item.kind == 2 && [5, 6, 7].includes(this.item.status)
    },
  },
}
