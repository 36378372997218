
import ChevronRight from "vue-material-design-icons/ChevronRight.vue"

export default {
  components: {
    ChevronRight,
  },

  props: {
    event_id: {
      type: [Number, String],
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    link: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    current_item_id() {
      return this.event.current_item_id
    },

    reserveState() {
      return this.item.reserve_state || 0
    },

    thumbnail() {
      return this.item.thumbnails[0]
    },

    image() {
      return this.item.images[0]
    },

    ended() {
      return this.item && ![10, 11].includes(this.item.status)
    },

    carat_mode() {
      return this.item.use_carat_price
    },

    description() {
      const d = this.item
      if (!d) return "..."
      return [d.model_number, d.model1, d.model2, d.misc].filter(Boolean).join(" ")
    },
  },
}
