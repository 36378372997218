
export default {
  data() {
    return {
      show: false,
    }
  },

  methods: {
    showMsg() {
      this.show = true
    },
  },

  computed: {
    closed() {
      return ["closed", "closing"].includes(this.$store.state.ws_state)
    },
  },

  beforeDestroy() {
    if (this.timer) clearTimeout(this.timer)
  },

  watch: {
    closed(v) {
      if (v) {
        this.timer = setTimeout(this.showMsg, 3000)
      } else {
        if (this.timer) clearTimeout(this.timer)
        this.show = false
      }
    },
  },
}
