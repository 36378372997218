export default (context) => {
  const getMs = () => {
    return 1100 - new Date().getMilliseconds()
  }

  const tick = () => {
    context.app.$pubsub.$emit("tick")
    setTimeout(tick, getMs())
  }

  setTimeout(tick, getMs())
}
